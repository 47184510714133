<template>
  <div
    class="scroll-button"
    @click="handleClick"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b)">
        <circle cx="40" cy="40" r="40" fill="black" fill-opacity="0.78" />
      </g>
      <path
        d="M40 47L40 31"
        :stroke="hover ? '#00E5FF' : 'white'"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M40 49L33 42"
        :stroke="hover ? '#00E5FF' : 'white'"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M40 49L47 42"
        :stroke="hover ? '#00E5FF' : 'white'"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <filter
          id="filter0_b"
          x="-4"
          y="-4"
          width="88"
          height="88"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hover: false, // Track hover state
    };
  },
  methods: {
    handleClick() {
        this.$emit('click');
    }
  },
};
</script>

<style scoped>
.scroll-button {
  /* position: fixed; */
  /* bottom: 20px; */
  /* right: 20px; */
  cursor: pointer;
  transition: transform 0.3s ease;
}

.scroll-button:hover {
  /* transform: scale(1.1); */
}
</style>
