<template>
  <main class="main profile-page page-inside" v-if="$parent.isAuth">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <!-- <div class="title big">
            {{ $t("Profile") }}
          </div> -->
          <div class="profile-form">
            <div class="profile-form-wrapper">
              <div class="wrapper left">
                <div class="balance">
                  <div class="title">
                    <img src="@/assets/wallet.svg" alt="" class="wallet-icon" />
                    <b>{{ balance }} {{ $parent.currency }}</b>
                  </div>
                  <button class="button" @click="$parent.openTopUpModal">
                    {{ $t("Top up") }}
                  </button>
                </div>

                <div class="text name-sur">
                  <div class="title">{{ name }} {{ surname }}</div>
                  <div class="desc">{{ email }}</div>
                </div>

                <div class="nav">
                  <div
                    class="item"
                    :class="{
                      active: $route.params.content == 'personal-information',
                    }"
                  >
                    <router-link
                      class="desc"
                      to="/profile/personal-information"
                      >{{ $t("Profile") }}</router-link
                    >
                  </div>
                  <div
                    class="item"
                    :class="{ active: $route.params.content == 'my-islands' }"
                  >
                    <router-link class="desc" to="/profile/my-islands">{{
                      $t("My islands")
                    }}</router-link>
                  </div>
                  <div
                    class="item"
                    :class="{
                      active: $route.params.content == 'deposit-history',
                    }"
                  >
                    <router-link class="desc" to="/profile/deposit-history">{{
                      $t("Deposit history")
                    }}</router-link>
                  </div>
                  <div
                    class="item"
                    :class="{
                      active: $route.params.content == 'order-history',
                    }"
                  >
                    <router-link class="desc" to="/profile/order-history">{{
                      $t("Order history")
                    }}</router-link>
                  </div>
                  <div
                    class="item"
                    :class="{ active: $route.params.content == 'buy-island' }"
                  >
                    <router-link class="desc" to="/profile/buy-island">{{
                      $t("Buy island")
                    }}</router-link>
                  </div>
                  <div class="item">
                    <a class="desc" @click="handleLogout">{{
                      $t("Log out")
                    }}</a>
                  </div>
                </div>
              </div>

              <div
                class="wrapper right price-block price-block-buy deposit-history"
                v-if="$route.params.content == 'deposit-history'"
              >
                <div class="title">{{ $t("Deposit history") }}</div>
                <BaseTable
                  :headers="depositHeaders"
                  :data="$parent.depositHistory"
                  :currentPage="depositCurrentPage"
                />
                <BasePagination
                  :totalItems="$parent.depositHistory.length"
                  :currentPage="depositCurrentPage"
                  @update:currentPage="depositCurrentPage = $event"
                />
              </div>

              <div
                class="wrapper right price-block price-block-buy personal-information"
                v-if="$route.params.content == 'personal-information'"
              >
                <PersonalInformation :user="user" />
              </div>

              <div
                class="wrapper right price-block price-block-buy order-history"
                v-if="$route.params.content == 'order-history'"
              >
                <div class="title">{{ $t("Order history") }}</div>
                <BaseTable
                  :headers="orderHeaders"
                  :data="$parent.orderHistory"
                  :currentPage="orderCurrentPage"
                />
                <BasePagination
                  :totalItems="$parent.orderHistory.length"
                  :currentPage="orderCurrentPage"
                  @update:currentPage="orderCurrentPage = $event"
                />
              </div>
              <div
                class="wrapper right price-block price-block-buy buy-island"
                v-if="$route.params.content == 'buy-island'"
              >
                <div class="title">{{ $t("Buy island") }}</div>
                <div class="list">
                  <div
                    :class="[
                      'item',
                      { active: $parent.islandToBuy.size === item.size },
                    ]"
                    @click="$emit('changeIslandToBuy', item)"
                    v-for="(item, i) in $parent.islands"
                    :key="i"
                  >
                    <MiniProductCard
                      :item="item"
                      :isSelected="item === $parent.islandToBuy"
                    />
                  </div>
                </div>
                <div class="price title island-price">
                  {{ $t("TOTAL") }}: {{ $parent.islandToBuy.price }}
                  {{ $parent.currency }}
                </div>
                <div
                  @click="$parent.openReadyModal"
                  class="button secondary island-buy"
                >
                  {{ $t("Buy") }}
                </div>
              </div>
              <div
                class="wrapper right price-block my-islands"
                v-if="$route.params.content == 'my-islands'"
              >
                <div class="title">{{ $t("My islands") }}</div>

                <div class="block">
                  <div class="subtitle" v-if="!$parent.myIslands.length">
                    {{
                      $t(
                        "You don't have any islands yet! Buy one, or add an existing from your friends!"
                      )
                    }}
                  </div>
                  <div class="list" v-if="$parent.myIslands.length">
                    <div
                      class="item"
                      @click="buyOptions(item)"
                      v-for="(item, i) in $parent.myIslands"
                      :key="i"
                    >
                      <MiniProductCard
                        :item="item"
                        :currency="$parent.currency"
                      />
                    </div>
                  </div>
                  <div class="buttons">
                    <button
                      class="button"
                      @click="$parent.openAddExistingIslandModal"
                    >
                      {{ $t("Add existing island") }}
                    </button>
                    <router-link to="/profile/buy-island" class="button">{{
                      $t("Buy island")
                    }}</router-link>
                  </div>
                </div>
              </div>
              <div
                class="wrapper right price-block price-block-buy buy-options"
                v-if="$route.params.content == 'buy-options'"
              >
                <!-- {{ $parent.islandToBuyOptions }} -->
                <router-link
                  to="/profile/my-islands"
                  class="back"
                ></router-link>
                <div class="title">{{ $t("Buy options") }}</div>
                <div
                  v-clipboard:copy="
                    $parent.islandToBuyOptions.host +
                    ':' +
                    $parent.islandToBuyOptions.port
                  "
                  v-if="
                    $parent.islandToBuyOptions &&
                    $parent.islandToBuyOptions.host
                  "
                  class="island-ip"
                >
                  {{
                    $parent.islandToBuyOptions.host +
                    ":" +
                    $parent.islandToBuyOptions.port
                  }}
                </div>
                <div class="checkbox-list" v-if="$parent.chosenIslandProducts">
                  <template v-for="(item, i) in $parent.chosenIslandProducts">
                    <label
                      class="checkbox-label-wrapper purchased"
                      v-if="item.purchased"
                      :key="i"
                    >
                      <input
                        :value="item"
                        :id="item.title"
                        type="checkbox"
                        v-model="optionsToBuy"
                      />
                      <div class="text">
                        <div class="checkbox"></div>
                        <img
                          :src="$parent.imgDomain + item.image_url"
                          class="img"
                        />
                        <div class="desc">{{ item.title }}</div>
                      </div>
                      <div class="price title">
                        {{ item.price }} {{ $parent.currency }}
                      </div>
                    </label>
                  </template>
                  <template v-for="(item, i) in $parent.chosenIslandProducts">
                    <label
                      class="checkbox-label-wrapper"
                      v-if="!item.purchased"
                      :key="i"
                    >
                      <input
                        :value="item"
                        :id="item.title"
                        type="checkbox"
                        v-model="optionsToBuy"
                      />
                      <div class="text">
                        <div class="checkbox"></div>
                        <img
                          :src="$parent.imgDomain + item.image_url"
                          class="img"
                        />
                        <div class="desc">{{ item.title }}</div>
                      </div>
                      <div class="price title">
                        {{ item.price }} {{ $parent.currency }}
                      </div>
                    </label>
                  </template>
                </div>
                <div v-if="optionsTotal" class="price title">
                  {{ $t("TOTAL") }}: {{ optionsTotal }} {{ $parent.currency }}
                </div>
                <button
                  :class="['button', { disabled: !optionsToBuy.length }]"
                  @click="$parent.openReadyModalProducts"
                >
                  {{ $t("Buy") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
// import { mapActions } from 'vuex';
import BasePagination from "../components/BasePagination.vue";
import BaseTable from "../components/BaseTable.vue";
import MiniProductCard from "../components/MiniProductCard.vue";
import PersonalInformation from "../components/tabs/PersonalInformation.vue";
export default {
  name: "Profile",
  props: ["optionsToBuyFinal", "totalBalance", "zeroOptionsChosen"],
  components: {
    MiniProductCard,
    PersonalInformation,
    BaseTable,
    BasePagination,
  },
  data: function () {
    return {
      depositCurrentPage: 1,
      orderCurrentPage: 1,
      depositHeaders: [
        { key: "created_at", label: this.$t("Date"), align: "left" },
        { key: "status", label: this.$t("Status"), align: "center" },
        {
          key: "sum",
          label: this.$t("Total"),
          align: "right",
          formatter: (value) => `${value} ${this.$parent.currency}`,
        },
      ],
      orderHeaders: [
        { key: "created_at", label: this.$t("Date"), align: "left" },
        { key: "title", label: this.$t("Title"), align: "left" },
        { key: "status", label: this.$t("Status"), align: "center" },
        {
          key: "sum",
          label: this.$t("Total"),
          align: "right",
          formatter: (value) => `${value} ${this.$parent.currency}`,
        },
      ],

      user: null,
      email: "",
      name: "",
      surname: "",
      phone: "",
      nickname: "",
      balance: "",
      profileUpdateSuccessMessaage: "",
      profileUpdateErrorMessaage: "",
      curPass: "",
      newPass: "",
      repeatPass: "",
      passUpdateSuccessMessaage: "",
      passUpdateErrorMessaage: "",
      optionsToBuy: [],
      optionsTotal: null,
    };
  },
  watch: {
    optionsToBuy() {
      this.optionsTotal = 0;
      for (let i = 0; i < this.optionsToBuy.length; i++) {
        this.optionsTotal += parseFloat(this.optionsToBuy[i].price);
      }

      this.optionsTotal = this.optionsTotal.toFixed(2);

      this.$emit("optionsTotalPriceChange", this.optionsTotal);
      this.$emit("optionsToBuyFinalChange", this.optionsToBuy);
    },
    optionsToBuyFinal() {
      if (!this.optionsToBuyFinal.length) {
        this.optionsTotal = "";
      }
    },
    zeroOptionsChosen() {
      if (this.zeroOptionsChosen) {
        this.optionsToBuy = [];
      }
    },
    totalBalance() {
      this.balance = this.totalBalance;
    },
  },
  mounted() {
    this.$http
      .get(process.env.VUE_APP_API + "user/profile")
      .then((res) => {
        this.user = res.data;
        this.balance = res.data.balance;
        this.email = res.data.email;
        this.name = res.data.name;
        this.surname = res.data.surname;
        this.phone = res.data.phone;
        this.nickname = res.data.nickname;
      })
      .catch(() => {});
  },
  methods: {
    // ...mapActions('auth', ['logout']),
    handleLogout() {
      // let result = this.logout()
      // if(result) {
      //   this.$router.push('/home')
      // }
      this.$emit("logout");
    },
    openOfferDetailsModal() {
      this.$emit("openOfferDetailsModal");
    },
    updateProfileData() {
      let profileData = {
        email: this.email,
        name: this.name,
        phone: this.phone,
        surname: this.surname,
        nickname: this.nickname,
      };
      this.$http
        .put(process.env.VUE_APP_API + "user/profile", profileData)
        .then((res) => {
          if (res.data.status == "OK") {
            this.profileUpdateSuccessMessaage = "Success";
            setTimeout(() => {
              this.profileUpdateSuccessMessaage = "";
            }, 3000);
          }
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignIn();
          }
          this.profileUpdateErrorMessaage = res.response.data.message;
          setTimeout(() => {
            this.profileUpdateErrorMessaage = "";
          }, 3000);
        });
    },
    updatePassword() {
      let passwordList = {
        cur_password: this.curPass,
        new_password: this.newPass,
        repeat_password: this.repeatPass,
      };
      this.$http
        .post(process.env.VUE_APP_API + "user/change-password", passwordList)
        .then((res) => {
          if (res.data.status == "OK") {
            this.passUpdateSuccessMessaage = "Success";
            (this.curPass = ""), (this.newPass = ""), (this.repeatPass = "");
            setTimeout(() => {
              this.passUpdateSuccessMessaage = "";
            }, 3000);
          }
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignIn();
          }
          this.passUpdateErrorMessaage = res.response.data.message;
          setTimeout(() => {
            this.passUpdateErrorMessaage = "";
          }, 3000);
        });
    },
    buyOptions(item) {
      this.$emit("changeIslandToBuyOptions", item);
      this.$router.push({ path: "/profile/buy-options" });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper .left .button {
  max-width: 126px;
}
.island-price {
  text-align: center;
}
.wrapper.personal-information {
  padding: 46px 80px;
}
.profile-page .hero-section .wrapper .name-sur {
  margin-bottom: 60px;
  margin-top: 64px;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}

.wrapper .nav .item a {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.019em;
  color: #ffffff;
}

.wrapper .nav .item.active a {
  color: #00e5ff;
}

.profile-page .hero-section .wrapper .name-sur .title {
  font-size: 24px;
  font-weight: 800;
  line-height: 30.24px;
}

.profile-page .hero-section .wrapper .name-sur .desc {
  font-size: 16px;
  font-weight: 400;
  line-height: 20.16px;
  color: #c65e7f;
  text-transform: initial;
}
.price-block .list {
  margin-top: 46px;
  margin-bottom: 71px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
}

.price-block .list .item {
}

.price-block .button {
  // margin: 57px auto 0;
  // max-width: 185px;
}

.subtitle {
  font-family: Outfit;
  font-size: 24px;
  font-weight: 700;
  line-height: 30.24px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #c65e7f;
}
.profile-page .hero-section .wrapper.my-islands {
  // display: flex;
  // flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 46px 104px;

  @media (max-width: 1024px) {
    padding: 32px;
  }
}

.profile-page .hero-section .wrapper.buy-options {
  // padding: 46px 104px;

  @media (max-width: 1024px) {
    // padding: 46px 40px;
  }
}

.profile-page .hero-section .wrapper.buy-options .price {
  margin-top: 20px;
  text-align: center;
}

.block {
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
  max-width: 602px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-top: 46px;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
}

.my-islands .buttons .button {
  max-width: 200px;
}

.buy-island .button {
  max-width: 185px;
  margin: 57px auto 0;
}
</style>
