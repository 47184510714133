import axios from "@/axios";

const state = {
  isAuthenticated: JSON.parse(localStorage.getItem("isAuthenticated")) || false, // Retrieve from localStorage
  user: null,
};

const mutations = {
  SET_AUTH_STATE(state, isAuthenticated) {
    state.isAuthenticated = isAuthenticated;
    localStorage.setItem("isAuthenticated", JSON.stringify(isAuthenticated));
  },
  SET_USER(state, user) {
    state.user = user;
    if (user) {
      //   localStorage.setItem("user", JSON.stringify(user));
    } else {
      //   localStorage.removeItem("user");
    }
  },
  UPDATE_USER_FIELD(state, { field, value }) {
    if (state.user) {
      state.user[field] = value;
      //   localStorage.setItem("user", JSON.stringify(state.user));
    }
  },
};

const actions = {
  async fetchUserProfile({ commit }) {
    try {
      const response = await axios.get("user/profile");
      commit("SET_USER", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching profile:", error);
      throw error;
    }
  },

  async updateProfileData({ commit }, profileData) {
    try {
      const response = await axios.put("user/profile", profileData);
      if (response.data.status === "OK") {
        // Update specific fields in Vuex store
        Object.entries(profileData).forEach(([key, value]) => {
          commit("UPDATE_USER_FIELD", { field: key, value });
        });
        return response.data;
      }
      throw new Error(response.data.message || "Profile update failed");
    } catch (error) {
      console.error("Error updating profile:", error);
      throw error;
    }
  },

  async checkAuth({ commit }) {
    try {
      const response = await axios.get("is-auth");
      const isAuthenticated = response.data.authenticated;
      commit("SET_AUTH_STATE", isAuthenticated);

      return isAuthenticated;
    } catch (error) {
      commit("SET_AUTH_STATE", false);
      commit("SET_USER", null);
      return false;
    }
  },

  async login({ commit }, credentials) {
    try {
      const response = await axios.post("login", credentials);
      if (response.data.status === "OK") {
        commit("SET_AUTH_STATE", true);
        commit("SET_USER", response.data?.user);
        return {
          success: true,
          data: response.data,
        };
      }
      return {
        success: false,
        error: response.data.message || "Login failed",
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.message || "Login failed",
      };
    }
  },

  async logout({ commit }) {
    try {
      await axios.post("logout");
      commit("SET_AUTH_STATE", false);
      commit("SET_USER", null);
      return true;
    } catch (error) {
      return false;
    }
  },

  clearAllData({ commit }) {
    commit("SET_AUTH_STATE", false);
    commit("SET_USER", null);
  },
};

const getters = {
  isAuthenticated: (state) => state.isAuthenticated,
  user: (state) => state.user,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
