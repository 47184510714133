var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"base-form-field"},[(_vm.label)?_c('label',{staticClass:"base-form-label"},[_c('div',{staticClass:"base-form-label-text"},[_vm._v(_vm._s(_vm.label))]),_c('div',{staticClass:"base-form-input-wrapper"},[_vm._t("icon-left",function(){return [(_vm.leftIcon)?_c('img',{staticClass:"base-form-icon base-form-icon-left",attrs:{"src":_vm.leftIcon,"alt":_vm.label || 'Input icon'}}):_vm._e()]}),_c('input',_vm._b({staticClass:"base-form-input",class:{
          'base-form-input-with-left-icon': _vm.$slots['icon-left'] || _vm.leftIcon,
          'base-form-input-with-right-icon':
            _vm.$slots['icon-right'] || _vm.rightIcon,
        },attrs:{"type":_vm.type},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.updateValue($event.target.value)}}},'input',_vm.$attrs,false)),_vm._t("icon-right",function(){return [(_vm.rightIcon)?_c('img',{staticClass:"base-form-icon base-form-icon-right",style:({ cursor: _vm.rightIconClickable ? 'pointer' : 'pointer' }),attrs:{"src":_vm.rightIcon,"alt":_vm.label || 'Input icon'},on:{"click":_vm.handleRightIconClick}}):_vm._e()]})],2)]):_c('div',{staticClass:"base-form-input-wrapper"},[_vm._t("icon-left",function(){return [(_vm.leftIcon)?_c('img',{staticClass:"base-form-icon base-form-icon-left",attrs:{"src":_vm.leftIcon,"alt":'Input icon'}}):_vm._e()]}),_c('input',_vm._b({staticClass:"base-form-input",class:{
        'base-form-input-with-left-icon': _vm.$slots['icon-left'] || _vm.leftIcon,
        'base-form-input-with-right-icon': _vm.$slots['icon-right'] || _vm.rightIcon,
      },attrs:{"type":_vm.type},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.updateValue($event.target.value)}}},'input',_vm.$attrs,false)),_vm._t("icon-right",function(){return [(_vm.rightIcon)?_c('img',{staticClass:"base-form-icon base-form-icon-right",style:({ cursor: _vm.rightIconClickable ? 'pointer' : 'default' }),attrs:{"src":_vm.rightIcon,"alt":'Input icon'},on:{"click":_vm.handleRightIconClick}}):_vm._e()]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }