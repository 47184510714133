<template>
  <main class="main home-page">
    <div class="main-wrapper">
      <HeroSection
        @scrollToPrice="$emit('scrollToPrice')"
        @scrollNextSection="scrollNextSection"
      />

      <WhyBuySection />

      <SafeSection />

      <OurPriceSection
        :isAuth="isAuth"
        :currency="currency"
        :islands="$parent.islands"
        :products="$parent.products"
      />

      <WhySection :isAuth="isAuth" />
    </div>
  </main>
</template>
<script>
import HeroSection from "../components/HeroSection.vue";
import WhyBuySection from "../components/WhyBuySection.vue";
import SafeSection from "../components/SafeSection.vue";
import OurPriceSection from "../components/OurPriceSection.vue";
import WhySection from "../components/WhySection.vue";

export default {
  name: "Home",
  props: {
    currency: {
      type: String,
    },
    isAuth: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    HeroSection,
    WhyBuySection,
    SafeSection,
    OurPriceSection,
    WhySection,
  },
  data: function () {
    return {
      imgDomain: "",
    };
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
  },
  methods: {
    scrollNextSection() {
      document.getElementById("section-2")?.scrollIntoView({
        behavior: "smooth",
      });
    },
  },
};
</script>
