<template>
  <div class="base-checkbox">
    <input
      type="checkbox"
      :id="id"
      :checked="value"
      @change="$emit('input', $event.target.checked)"
    />
    <label :for="id" class="checkbox-label">
      <slot></slot>
    </label>
  </div>
</template>

<script>
export default {
  name: "BaseCheckbox",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.base-checkbox {
  display: flex;
  align-items: center;
  gap: 14px;
}

.base-checkbox input[type="checkbox"] {
  padding: 0;
  flex: 0 0 24px;
  width: 24px;
  height: 24px;
  border-radius: 6px;
  background: #271a28;
  border: 1px solid #6b576c;
  appearance: none;
  cursor: pointer;
  position: relative;
}

.base-checkbox input[type="checkbox"]:checked {
  // background-color: #00e5ff;
  // border: none;
}

.base-checkbox input[type="checkbox"]::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px; /* Adjust to fit your SVG size */
  height: 12px;
  background: url("../../src/assets/check-mark.svg") no-repeat center;
  background-size: contain;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.base-checkbox input[type="checkbox"]:checked::before {
  opacity: 1;
}

.base-checkbox .checkbox-label {
  font-size: 14px;
  line-height: 1.26;
  color: #a18da1;
  cursor: pointer;
}

.base-checkbox .checkbox-label a {
  color: #c65e7f;
  // text-decoration: underline;
}

.base-checkbox .checkbox-label a:hover {
  color: #00E5FF;
  opacity: 1;
  text-decoration: underline;
}
</style>
