<template>
  <div id="app" :class="['app ' + $route.name]">
    <transition name="fade">
      <div id="page" class="page" v-if="pageLoaded">
        <Header @scrollToPrice="scrollToPrice" />
        <router-view
          :isAuth="isAuth"
          :totalBalance="totalBalance"
          :currency="currency"
          :optionsToBuyFinal="optionsToBuyFinal"
          :zeroOptionsChosen="zeroOptionsChosen"
          @login="login"
          @logout="logout"
          @forgotPass="forgotPass"
          @registration="registration"
          @scrollToPrice="scrollToPrice"
          @changeIslandToBuy="changeIslandToBuy"
          @changeIslandToBuyOptions="changeIslandToBuyOptions"
          @optionsTotalPriceChange="optionsTotalPriceChange"
          @optionsToBuyFinalChange="optionsToBuyFinalChange"
        />

        <modal-manager
          :error="error"
          :successMessage="successMessage"
          @clearError="clearError"
          @submitLogin="login"
          @submitRegistration="registration"
          @submitForgotPassword="forgotPass"
        />

        <Footer :socialLinks="socialLinks" />
        <transition name="fade">
          <ReadyModal v-if="readyModalVisible" />
        </transition>
        <transition name="fade">
          <ReadyModalProducts
            v-if="readyModalProductsVisible"
            @nicknameChange="nicknameChange"
          />
        </transition>
        <transition name="fade">
          <AddExistingIslandModal
            v-if="addExistingIslandModalVisible"
            @hostChange="hostChange"
            @portChange="portChange"
          />
        </transition>
        <transition name="fade">
          <TopUpModal v-if="topUpModalVisible" />
        </transition>
        <div class="modal modal-overlay" v-if="cookesModalIsVisible">
          <div class="cookies-modal">
            <div class="title">Cookie Information</div>
            <div class="desc chekbox-label">
              Our website relies on cookies to operate smoothly and enhance your
              browsing experience. Essential cookies are automatically enabled
              for core functions. We also use other types of cookies, which you
              can control through your preferences. Learn more in our
              <router-link :to="cookiesLink" class="title" v-if="cookiesLink">{{
                $t("Cookie Policy")
              }}</router-link>
            </div>
            <button class="button blue" @click="acceptCookies">
              Confirm & Proceed
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import ReadyModal from "./components/ReadyModal.vue";
import ReadyModalProducts from "./components/ReadyModalProducts.vue";
import AddExistingIslandModal from "./components/AddExistingIslandModal.vue";
import TopUpModal from "./components/TopUpModal.vue";
import { scroller } from "vue-scrollto/src/scrollTo";
import { loadScriptsFromString } from "@/utils/load_scripts";
import i18n from "@/i18n";
import { mapActions, mapGetters } from "vuex";
import ModalManager from "./components/ModalManager.vue";
import { MODALS } from "./store/modules/modals";

export default {
  name: "app",
  components: {
    Header,
    Footer,
    ReadyModal,
    ReadyModalProducts,
    TopUpModal,
    AddExistingIslandModal,
    ModalManager,
  },
  data: function () {
    return {
      nav: [
        {
          title: "HOME",
          path: "/home",
        },
        {
          title: "CONTACT US",
          path: "/contact-us",
        },
        {
          title: "FAQ",
          path: "/faq",
        },
      ],
      pageLoaded: false,
      readyModalVisible: false,
      readyModalProductsVisible: false,
      topUpModalVisible: false,
      error: "",
      successMessage: "",
      textPageTitle: "",
      name: "",
      textPageHeader: "",
      textPageContent: "",
      orderHistory: "",
      depositHistory: "",
      imgDomain: "",
      currency: "",
      islandToBuy: {},
      islandToBuyOptions: {},
      islands: [],
      products: [],
      myIslands: [],
      chosenIslandProducts: [],
      optionsTotalPrice: null,
      optionsToBuyFinal: [],
      nickname: "",
      addExistingIslandModalVisible: false,
      host: "",
      port: "",
      totalBalance: "",
      zeroOptionsChosen: false,
      socialLinks: [],
      minTopUpAmount: null,
      settings: {},
      cookesModalIsVisible: false,
    };
  },
  watch: {
    $route() {
      window.scrollTo(0, 0);
      if (this.isAuth) {
        this.getOrderHistory();
      }
      this.clearError();
    },
  },
  methods: {
    ...mapActions("sound", ["initSound"]),
    ...mapActions("modals", ["closeModal"]),
    ...mapActions("staticPages", ["fetchPages"]),

    fetchTranslations() {
      this.$http.get(process.env.VUE_APP_API + "localizations").then((res) => {
        i18n.setLocaleMessage("en", res.data);
        i18n.locale = "en";
      });
    },
    getGoogleTagManagerSnippet() {
      const self = this;

      return this.$http
        .get(process.env.VUE_APP_API + "settings")
        .then((res) => {
          self.minTopUpAmount = res.data.payload.min_top_up_amount;
          const snippet = res.data.payload.google_tag;
          self.settings = res.data.payload;

          if (snippet) {
            loadScriptsFromString(snippet);
          }
        })
        .catch((error) => {
          console.error("Error fetching Google Tag Manager snippet:", error);
        });
    },
    nicknameChange(user) {
      this.nickname = user;
    },
    hostChange(host) {
      this.host = host;
    },
    portChange(port) {
      this.port = port;
    },
    openAddExistingIslandModal() {
      this.addExistingIslandModalVisible = true;
    },
    closeAddExistingIslandModal() {
      this.successMessage = "";
      this.error = "";
      this.addExistingIslandModalVisible = false;
    },
    optionsToBuyFinalChange(items) {
      this.optionsToBuyFinal = items;
    },
    optionsTotalPriceChange(sum) {
      this.optionsTotalPrice = sum;
    },
    openReadyModalProducts() {
      this.readyModalProductsVisible = true;
    },
    closeReadyModalProducts() {
      this.successMessage = "";
      this.error = "";
      this.readyModalProductsVisible = false;
    },
    openReadyModal() {
      this.readyModalVisible = true;
    },
    closeReadyModal() {
      this.successMessage = "";
      this.error = "";
      this.readyModalVisible = false;
    },
    openTopUpModal() {
      this.topUpModalVisible = true;
    },
    closeTopUpModal() {
      this.topUpModalVisible = false;
    },
    async login(data) {
      try {
        const result = await this.$store.dispatch("auth/login", data);

        if (result.success) {
          // Close modal and clear errors
          this.closeModal();
          this.clearError();

          // Load user data
          await Promise.all([
            this.getName(),
            this.getOrderHistory(),
            this.getMyIslands(),
          ]);

          // Navigate to profile
          this.$router.push({ path: "/profile/personal-information" });
        } else {
          this.error = result.error;
          setTimeout(() => {
            this.error = "";
          }, 2000);
        }
      } catch (error) {
        this.error = error.response?.data?.message || "Login failed";
        setTimeout(() => {
          this.error = "";
        }, 2000);
      }
    },

    forgotPass(data) {
      let self = this;
      this.$http
        .post(process.env.VUE_APP_API + "user/reset-password", data)
        .then((res) => {
          if (res.data.status == "OK") {
            this.successMessage = "Success";
            this.error = "";
            setTimeout(() => {
              self.successMessage = "";
            }, 2000);
          } else {
            this.error = res.response.data.message;
            this.successMessage = "";
            setTimeout(() => {
              self.error = "";
            }, 2000);
          }
        })
        .catch((error) => {
          this.error = error.response.data.message;
          this.successMessage = "";
          setTimeout(() => {
            self.error = "";
          }, 2000);
        });
    },
    async registration(data) {
      try {
        // Initial validation
        if (data.password !== data.passConfirm) {
          this.error = "Password confirmation failed!";
          setTimeout(() => {
            this.error = "";
          }, 2000);
          return;
        }

        const response = await this.$http.post(
          process.env.VUE_APP_API + "register",
          data
        );

        if (response.data.status === "OK") {
          // Update Vuex auth state
          await this.$store.dispatch("auth/checkAuth");

          // Close modal and clear errors
          this.closeModal();
          this.clearError();

          // Load user data
          await Promise.all([
            this.getName(),
            this.getOrderHistory(),
            this.getMyIslands(),
          ]);

          // Navigate to profile
          this.$router.push({ path: "/profile/personal-information" });
        } else {
          this.error = response.data.message;
          setTimeout(() => {
            this.error = "";
          }, 2000);
        }
      } catch (error) {
        this.error = error.response?.data?.message || "Registration failed";
        setTimeout(() => {
          this.error = "";
        }, 2000);
      }
    },
    async logout() {
      const success = await this.$store.dispatch("auth/logout");
      if (success) {
        this.$router.push({ path: "/" });
      }
    },
    clearError() {
      this.error = "";
      this.successMessage = "";
    },
    getOrderHistory() {
      this.$http
        .get(process.env.VUE_APP_API + "purchase/history")
        .then((res) => {
          this.orderHistory = res.data.payload;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.openSignIn();
          }
        });
      this.$http
        .get(process.env.VUE_APP_API + "orders/history")
        .then((res) => {
          this.depositHistory = res.data.payload;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.openSignIn();
          }
        });
    },
    formatDate(num) {
      let objectDate = new Date(num * 1000);
      let day = objectDate.getDate();
      if (day < 10) {
        day = "0" + day;
      }
      let month = objectDate.getMonth() + 1;
      if (month < 10) {
        month = `0${month}`;
      }
      let year = objectDate.getFullYear();

      function padTo2Digits(x) {
        return String(x).padStart(2, "0");
      }
      let hoursAndMinutes =
        padTo2Digits(objectDate.getHours()) +
        ":" +
        padTo2Digits(objectDate.getMinutes());
      let format = day + "." + month + "." + year + " " + hoursAndMinutes;

      return format;
    },
    cookiesModal() {
      const cookiesAccepted = localStorage.getItem("cookiesAccepted");

      if (!cookiesAccepted) {
        this.cookesModalIsVisible = true;
      }
    },
    declineCookies() {
      this.cookesModalIsVisible = false;
    },
    acceptCookies() {
      localStorage.setItem("cookiesAccepted", "true");
      this.cookesModalIsVisible = false;
    },
    async getTextPages() {
      try {
        await this.fetchPages();
      } catch (error) {
        if (error.response?.status === 401) {
          this.openSignIn();
        }
      }
    },
    getSocialLinks() {
      return this.$http
        .get(process.env.VUE_APP_API + "social-links")
        .then((res) => {
          this.socialLinks = res.data.payload;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.openSignIn();
          }
        });
    },
    openSignIn() {
      this.error = "";
      this.$store.dispatch("modals/openModal", {
        modalName: MODALS.LOGIN,
      });
    },
    openSignUp() {
      this.$store.dispatch("modals/openModal", {
        modalName: MODALS.SIGNUP,
      });
    },
    openRecover() {
      this.$router.push({ path: "/recover" });
    },
    scrollToPrice() {
      if (this.$route.name != "Home") {
        this.$router.push({ path: "/" });
      }
      setTimeout(() => {
        const firstScrollTo = scroller();
        firstScrollTo("#price");
      }, 200);
    },
    changeIslandToBuy(item) {
      this.islandToBuy = item;
    },
    changeIslandToBuyOptions(item) {
      this.islandToBuyOptions = item;
      this.getIslandProducts();
    },
    buyIsland() {
      let data = {
        size: this.islandToBuy.size,
      };
      this.$http
        .post(process.env.VUE_APP_API + "purchase/container", data)
        .then((res) => {
          if (res.data.status == "OK") {
            this.successMessage = "Success";
            this.getMyIslands();
            this.getBalance();
            this.$router.push({ path: "/profile/personal-information" });
          } else {
            this.error = res.data.message;
          }
        })
        .catch((res) => {
          this.error = res.response.data.message;
        });
    },
    addExistingIsland() {
      let data = {
        host: this.host,
        port: this.port,
      };
      this.$http
        .post(process.env.VUE_APP_API + "user/containers/attach", data)
        .then((res) => {
          if (res.data.status == "OK") {
            this.successMessage = "Success";
            this.getMyIslands();
            this.$router.push({ path: "/profile/personal-information" });
          } else {
            this.error = res.data.message;
          }
        })
        .catch((res) => {
          this.error = res.response.data.message;
          console.log(res);
        });
    },
    getIslandsToBuy() {
      this.$http
        .get(process.env.VUE_APP_API + "containers")
        .then((res) => {
          this.islands = res.data.payload;
          this.islandToBuy = this.islands[0];
        })
        .catch(() => {});
    },
    getMyIslands() {
      this.$http
        .get(process.env.VUE_APP_API + "user/containers")
        .then((res) => {
          this.myIslands = res.data.payload;
        })
        .catch(() => {});
    },
    getProducts() {
      this.$http
        .get(process.env.VUE_APP_API + "products/?type=options")
        .then((res) => {
          this.products = res.data.payload;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.openSignIn();
          }
        });
    },
    getIslandProducts() {
      this.$http
        .get(
          process.env.VUE_APP_API +
            "purchase/products/list?container_id=" +
            this.islandToBuyOptions.id
        )
        .then((res) => {
          this.chosenIslandProducts = res.data.payload;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.openSignIn();
          }
        });
    },
    buyProducts() {
      this.zeroOptionsChosen = false;
      let optionIds = [];
      for (let i = 0; i < this.optionsToBuyFinal.length; i++) {
        optionIds.push(this.optionsToBuyFinal[i].id);
      }
      let data = {
        nickname: this.nickname,
        container_id: this.islandToBuyOptions.id,
        product_ids: optionIds,
      };
      this.$http
        .post(process.env.VUE_APP_API + "purchase/product", data)
        .then((res) => {
          if (res.data.status == "OK") {
            this.successMessage = "Success";
            this.$router.push({ path: "/profile/personal-information" });
            this.error = "";
            this.optionsToBuyFinal = [];
            this.chosenIslandProducts = [];
            this.islandToBuyOptions = [];
            this.zeroOptionsChosen = true;
            this.getBalance();
          } else {
            this.error = res.response.data.message;
          }
        })
        .catch((res) => {
          this.error = res.response.data.message;
        });
    },
    getBalance() {
      this.$http
        .get(process.env.VUE_APP_API + "user/profile")
        .then((res) => {
          this.totalBalance = res.data.balance;
        })
        .catch(() => {});
    },
    getName() {
      this.$http
        .get(process.env.VUE_APP_API + "user/profile")
        .then((res) => {
          this.name = res.data.name;
          this.nickname = res.data.nickname;
        })
        .catch(() => {});
    },
  },
  mounted: async function () {
    try {
      const isAuthenticated = await this.$store.dispatch("auth/checkAuth");

      if (isAuthenticated) {
        await Promise.all([
          this.getName(),
          this.getOrderHistory(),
          this.getMyIslands(),
        ]);
      }
    } catch (error) {
      console.error("Auth check failed:", error);
      this.$store.dispatch("auth/clearAllData");
    }

    this.initSound();
    this.fetchTranslations();
    this.currency = process.env.VUE_APP_CURRENCY;
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.pageLoaded = true;
    let self = this;

    await self.getGoogleTagManagerSnippet();

    this.cookiesModal();
    this.getProducts();
    this.getTextPages();
    this.getIslandsToBuy();
    this.getSocialLinks();
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
    ...mapGetters("staticPages", ["getCookiePage"]),

    cookiesLink() {
      const page = this.getCookiePage;
      return page ? `/pages/${page.slug}` : null;
    },
    isAuth() {
      return this.isAuthenticated;
    },
  },
};
</script>
