<!-- src/components/common/icons/IconSound.vue -->
<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :class="{
      'icon-sound': true,
      'icon-sound--hovered': isHovered,
      'icon-sound--muted': isMuted,
    }"
    @mouseenter="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <g filter="url(#filter0_b_64_128)">
      <circle cx="32" cy="32" r="32" fill="black" fill-opacity="0.78" />
    </g>

    <g clip-path="url(#clip0_64_128)">
      <!-- Playing state -->
      <template v-if="!isMuted">
        <path
          d="M35 28C35.621 28.4657 36.125 29.0697 36.4721 29.7639C36.8193 30.4582 37 31.2238 37 32C37 32.7762 36.8193 33.5418 36.4721 34.2361C36.125 34.9303 35.621 35.5343 35 36"
          :stroke="isHovered ? '#00E5FF' : 'white'"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M37.7002 25C38.7442 25.8437 39.5862 26.9101 40.1647 28.1213C40.7431 29.3325 41.0433 30.6578 41.0433 32C41.0433 33.3422 40.7431 34.6675 40.1647 35.8787C39.5862 37.0899 38.7442 38.1563 37.7002 39"
          :stroke="isHovered ? '#00E5FF' : 'white'"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </template>

      <!-- Muted state -->
      <template v-else>
        <path
          d="M36 30L40 34M40 30L36 34"
          :stroke="isHovered ? '#00E5FF' : 'white'"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </template>

      <!-- Common speaker path -->
      <path
        d="M26 35.0002H24C23.7348 35.0002 23.4804 34.8949 23.2929 34.7073C23.1054 34.5198 23 34.2654 23 34.0002V30.0002C23 29.735 23.1054 29.4806 23.2929 29.2931C23.4804 29.1056 23.7348 29.0002 24 29.0002H26L29.5 24.5002C29.5874 24.3304 29.7326 24.1975 29.9095 24.1255C30.0863 24.0535 30.2831 24.0471 30.4643 24.1075C30.6454 24.1678 30.799 24.291 30.8972 24.4548C30.9955 24.6185 31.0319 24.812 31 25.0002V39.0002C31.0319 39.1885 30.9955 39.3819 30.8972 39.5457C30.799 39.7094 30.6454 39.8326 30.4643 39.893C30.2831 39.9534 30.0863 39.947 29.9095 39.8749C29.7326 39.8029 29.5874 39.67 29.5 39.5002L26 35.0002Z"
        :stroke="isHovered ? '#00E5FF' : 'white'"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>

    <defs>
      <filter
        id="filter0_b_64_128"
        x="-4"
        y="-4"
        width="72"
        height="72"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_64_128"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_64_128"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_64_128">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(20 20)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "IconSound",

  props: {
    size: {
      type: Number,
      default: 64,
    },
    isMuted: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const isHovered = ref(false);

    return {
      isHovered,
    };
  },
});
</script>

<style lang="scss">
.icon-sound {
  transition: all 0.3s ease;

  &:hover {
    cursor: pointer;
  }
}
</style>
