<template>
  <section class="section why-section" id="section-2">
    <div class="why-section-container wrapper">
      <h2 class="why-section-title">
        {{ $t("Why Buy a Private Island") }}
      </h2>
      <h3 class="why-section-subtitle">
        {{ $t("in Minecraft?") }}
      </h3>

      <div class="why-section-cards">
        <div class="why-card">
          <img
            src="@/assets/why-1.png"
            alt="Why buy island 1"
            class="why-card-image"
          />
          <p class="why-card-text">
            {{
              $t(
                "Imagine owning a piece of paradise, a place where you can build and create your own world without any limitations."
              )
            }}
          </p>
        </div>

        <div class="why-card">
          <img
            src="@/assets/why-2.png"
            alt="Why buy island 2"
            class="why-card-image"
          />
          <p class="why-card-text">
            {{
              $t(
                "Our private islands offer the perfect getaway from the hustle and bustle of the crowded Minecraft servers. You can enjoy the privacy and security of your own island, free from trolls and griefers."
              )
            }}
          </p>
        </div>

        <div class="why-card">
          <img
            src="@/assets/why-3.png"
            alt="Why buy island 3"
            class="why-card-image"
          />
          <p class="why-card-text">
            {{
              $t(
                "At Private Island Sales, we offer a wide range of private islands to choose from. Whether you are looking for a tropical paradise, a winter wonderland or a floating island in the sky, we have got you covered!"
              )
            }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "WhyBuySection",
};
</script>

<style lang="scss" scoped>
.why-section {
  padding-top: 15px;
  padding-bottom: 140px;

  &-container {
  }

  &-title {
    font-family: "Outfit", sans-serif;
    font-size: 64px;
    font-weight: 700;
    line-height: 80px;
    text-align: center;
    margin-bottom: 24px;
  }

  &-subtitle {
    font-family: "Outfit", sans-serif;
    font-size: 32px;
    font-weight: 200;
    line-height: 40px;
    text-align: center;
    color: #c65e7f;
    margin-bottom: 70px;
  }

  &-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    max-width: 1227px;
    margin: 0 auto;
  }
}

.why-card {
  background: #433144;
  border-radius: 18px;
  padding: 46px 57px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &-image {
    width: 121px;
    height: 120px;
    object-fit: contain;
    margin-bottom: 70px;

    .why-card:nth-child(2) & {
      margin-bottom: 50px;
    }

    .why-card:nth-child(3) & {
      margin-bottom: 40px;
    }
  }

  &-text {
    font-family: "Outfit", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: white;
  }
}

@media (max-width: 1200px) {
  .why-section-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .why-section {
    &-title {
      font-size: 48px;
      line-height: 60px;
    }

    &-subtitle {
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 50px;
    }

    &-cards {
      grid-template-columns: 1fr;
      max-width: 500px;
    }
  }

  .why-card {
    padding: 30px 20px;
  }
}

@media (max-width: 480px) {
  .why-section {

    &-title {
      font-size: 36px;
      line-height: 45px;
    }
  }
}
</style>
