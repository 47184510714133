export default {
    namespaced: true,

    state: () => ({
        isEnabled: false,
        audio: null
    }),

    getters: {
        isEnabled: state => state.isEnabled
    },

    mutations: {
        INIT_AUDIO(state) {
            if (!state.audio) {
                state.audio = new Audio(require('@/assets/audio/background-music.mp3'));
                state.audio.loop = true;
            }
        },

        SET_ENABLED(state, value) {
            state.isEnabled = value;
        }
    },

    actions: {
        initSound({ commit }) {
            commit('INIT_AUDIO');
        },

        toggle({ commit, state }) {
            if (state.isEnabled) {
                state.audio.pause();
            } else {
                state.audio.play();
            }
            commit('SET_ENABLED', !state.isEnabled);
        }

    }
};