import Vue from "vue";
import VueRouter from "vue-router";
// import store from '@/store';

import Home from "./pages/Home.vue";
// import Login from './pages/Login.vue';
import Profile from "./pages/Profile.vue";
import PaymentSuccessPage from "./pages/PaymentSuccessPage.vue";
import PaymentFailPage from "./pages/PaymentFailPage.vue";
import TextPage from "./pages/TextPage.vue";
// import Registration from './pages/Registration.vue';
import Recover from "./pages/Recover.vue";
import ContactUs from "./pages/ContactUs.vue";
import Faq from "./pages/Faq.vue";
import PageNotFound from "./pages/PageNotFound.vue";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  { path: "/", redirect: "/home" },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  // {
  // 	path: '/login',
  // 	name: 'Login',
  // 	component: Login,
  // 	meta: { guest: true },
  // },
  {
    path: "/recover",
    name: "Recover",
    component: Recover,
    meta: { guest: true },
  },
  // {
  // 	path: '/registration',
  // 	name: 'Registration',
  // 	component: Registration,
  // 	meta: { guest: true },
  // },
  {
    path: "/profile/:content",
    name: "Profile",
    component: Profile,
    meta: { requiresAuth: true },
  },
  {
    path: "/success-payment",
    name: "PaymentSuccessPage",
    component: PaymentSuccessPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/fail-payment",
    name: "PaymentFailPage",
    component: PaymentFailPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/pages/:identifier",
    name: "TextPage",
    component: TextPage,
    props: true,
  },
  {
    path: "/contact-us",
    name: "ContactUs",
    component: ContactUs,
  },
  {
    path: "/faq",
    name: "Faq",
    component: Faq,
  },
  {
    path: "*",
    name: "PageNotFound",
    component: PageNotFound,
    props: { title: "PageNotFound" },
  },
];

// Create the router instance
const router = new VueRouter({
  routes,
  mode: "history",
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // Check auth state in store
    if (!store.state.auth.isAuthenticated) {
      next({ path: "/home" });
      store.dispatch("modals/openModal", { modalName: "LOGIN" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
