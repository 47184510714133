import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import sound from './modules/sound'
import auth from './modules/auth'
import faq from './modules/faq'
import modals from './modules/modals'
import staticPages from './modules/staticPages'
Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    modules: {
        auth,
        app,
        faq,
        modals,
        sound,
        staticPages
    },
    strict: debug,
})