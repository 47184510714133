<template>
  <section class="safe-section">
    <div class="safe-container wrapper">
      <div class="safe-content">
        <img
          src="./../assets/safe-bg.png"
          class="safe-background"
          alt="Safe background"
        />

        <img
          src="./../assets/safe-left-bottom.png"
          class="safe-corner"
          alt="Safe corner decoration"
        />

        <h2 class="safe-heading">
          <span class="safe-heading-primary">{{ $t("Safe") }}</span>
          <span class="safe-heading-secondary">{{ $t("and Secure") }}</span>
        </h2>
        <div class="safe-info-card">
          <p class="safe-info-text">
            {{
              $t(
                "Our website is committed to protecting your personal information. We use state-of-the-art security measures to ensure that your data is safe and secure. Your privacy is important to us, and we will never share your information with third parties."
              )
            }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SafeSection",
};
</script>

<style lang="scss" scoped>
.safe-section {
  position: relative;
  margin-top: 24px;
  width: 100%;
  overflow: visible;
}

.safe-container {
  max-width: 1503px;
}

.safe-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.safe-corner {
  position: absolute;
  left: 0;
  // bottom: 0;
  z-index: 2;
  pointer-events: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  // max-width: 25%;

  @media (max-width: 1600px) {
    max-width: 30%;
  }

  @media (max-width: 768px) {
    max-width: 30%;
  }
}

.safe-content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  min-height: 651px;

  @media (max-width: 1024px) {
    min-height: 500px;
    padding: 30px 16px;
  }

  @media (max-width: 768px) {
    min-height: 400px;
    padding: 24px 12px;
  }
}

.safe-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 64px;
  text-align: center;

  @media (max-width: 768px) {
    margin-bottom: 32px;
  }
}

.safe-heading-primary {
  font-family: "Outfit", sans-serif;
  font-size: 64px;
  font-weight: 700;
  line-height: 1.27;
  color: #ffffff;
  margin-bottom: 24px;

  @media (max-width: 1024px) {
    font-size: 48px;
    line-height: 1.2;
    margin-bottom: 16px;
  }

  @media (max-width: 768px) {
    font-size: 36px;
    margin-bottom: 12px;
  }
}

.safe-heading-secondary {
  font-family: "Outfit", sans-serif;
  font-size: 32px;
  font-weight: 200;
  line-height: 1.25;
  color: #c65e7f;

  @media (max-width: 1024px) {
    font-size: 24px;
  }

  @media (max-width: 768px) {
    font-size: 20px;
  }
}

.safe-info-card {
  max-width: 602px;
  width: 100%;
  background: rgba(0, 0, 0, 0.64);
  backdrop-filter: blur(4px);
  border-radius: 18px;
  padding: 44px 103px;

  @media (max-width: 1024px) {
    // padding: 32px 60px;
  }

  @media (max-width: 480px) {
    padding: 24px 20px;
    border-radius: 12px;
  }
}

.safe-info-text {
  font-family: "Outfit", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  text-align: center;
  color: #ffffff;

  @media (max-width: 768px) {
    font-size: 14px;
  }
}
</style>
