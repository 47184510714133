import axios from "@/axios";

export default {
    namespaced: true,
    state: {
        faqs: [],
        loading: false,
        error: null,
    },
    mutations: {
        SET_FAQS(state, faqs) {
            state.faqs = faqs;
        },
        SET_LOADING(state, loading) {
            state.loading = loading;
        },
        SET_ERROR(state, error) {
            state.error = error;
        },
    },
    actions: {
        async fetchFaqs({ commit }, langId = null) {
            // Create an empty object for params
            let params = {};

            // Check if langId is valid and if so, add it to the params
            if (typeof langId === "number" && langId > 0) {
                params.lang_id = langId;
            } else {
                console.log("No valid lang_id provided. Falling back to default.");
            }

            // Set loading and error states
            commit("SET_LOADING", true);
            commit("SET_ERROR", null);

            try {
                // Make the GET request with conditional params
                const response = await axios.get('/faq', { params });
                commit("SET_FAQS", response.data.payload);
            } catch (error) {
                // Handle error and commit the error state
                commit("SET_ERROR", error.message);
                console.error("Error fetching FAQs:", error);
            } finally {
                // Set loading to false
                commit("SET_LOADING", false);
            }
        }
    }
    ,
};
