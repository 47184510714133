
import Vue from 'vue'
import App from './App.vue'
import router from './router.js'
import VueClipboard from 'vue-clipboard2'
import i18n from './i18n'
import store from './store';
import axios from './axios'
import SoundToggle from './components/SoundToggle.vue'

Vue.component('BaseInput', () => import('./components/BaseInput.vue'));
Vue.component('BaseTextarea', () => import('./components/BaseTextarea.vue'));
Vue.component('BaseModal', () => import('./components/modals/BaseModal.vue'));
Vue.component('BaseCheckbox', () => import('./components/BaseCheckbox.vue'));
Vue.component('BaseSelect', () => import('./components/BaseSelect.vue'));

Vue.component('SoundToggle', SoundToggle);

VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard)
Vue.prototype.$http = axios

global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

import './../node_modules/slick-carousel/slick/slick.css';
import '../public/css/style.scss';
import 'promise-polyfill/src/polyfill';

new Vue({
	router: router,
	store,
	i18n,
	render: h => h(App),
}).$mount('#app')
