<template>
  <section class="price-section" id="price">
    <div class="wrapper">
      <div class="price-header">
        <h2 class="price-title">{{ $t("Our price") }}</h2>
        <p class="price-subtitle">{{ $t("Island") }}</p>
      </div>

      <div class="island-cards" v-if="islands">
        <div v-for="(item, i) in islands" :key="i" class="island-card-wrapper">
          <ProductCard :item="item" :currency="currency" :is-auth="isAuth" />
        </div>
      </div>

      <div class="options-section" v-if="products">
        <h2 class="options-title">{{ $t("Options") }}</h2>
        <p class="options-subtitle">{{ $t("Price") }}</p>

        <div class="options-grid">
          <div
            v-for="(item, i) in products"
            :key="i"
            class="option-card-wrapper"
          >
            <OptionCard :item="item" :currency="currency" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ProductCard from "./ProductCard.vue";
import OptionCard from "./OptionCard.vue";

export default {
  name: "SectionFour",
  components: { ProductCard, OptionCard },
  props: {
    isAuth: Boolean,
    currency: String,
    islands: {
      type: Array,
      default: () => [],
    },
    products: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.price-section {
  margin-top: 9px;
}

.price-header {
  margin-bottom: 64px;
}

.price-title {
  font-size: 128px;
  font-weight: 900;
  line-height: 161px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 24px;
}

.price-subtitle {
  font-size: 32px;
  font-weight: 200;
  line-height: 40px;
  text-align: center;
  color: #c65e7f;
}

.island-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 95px 24px;
  justify-content: center;
  align-items: flex-start;
}

.island-card-wrapper {
  // width: 100%;
}

.option-card-wrapper {
  // width: 100%;
}

.options-section {
  margin-top: 122px;
  text-align: center;
}

.options-title {
  font-size: 64px;
  font-weight: 700;
  line-height: 81px;
  margin-bottom: 24px;
}

.options-subtitle {
  font-size: 32px;
  font-weight: 200;
  line-height: 40px;
  color: #c65e7f;
  margin-bottom: 46px;
}

.options-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 46px 24px;
  justify-content: center;
}

@media (max-width: 1280px) {
  .island-cards {
    justify-content: center;
  }

  .price-title {
    font-size: 96px;
    line-height: 120px;
  }
}

@media (max-width: 768px) {
  .price-title {
    font-size: 64px;
    line-height: 80px;
  }

  .island-cards {
  }

  .island-card-wrapper {
    width: 100%;
    max-width: 393px;
    margin: 0 auto;
  }

  .options-grid {
    gap: 24px 16px;
  }
}
</style>
