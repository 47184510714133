// store/modules/modals.js
export const MODALS = {
    LOGIN: 'login',
    SIGNUP: 'signup',
    FORGOT_PASSWORD: 'forgotPassword',
    AMOUNT: 'amount',
    CONTACT_INFO: 'contactInfo',
    ADD_ISLAND: 'addIsland'
}

export default {
    namespaced: true,

    state: {
        activeModal: null,
        error: null,
        modalProps: null,
        errorTimeoutId: null, // Add this to track the timeout
    },

    mutations: {
        SET_ACTIVE_MODAL(state, { modalName, props = null }) {
            state.activeModal = modalName
            state.modalProps = props
            state.error = null
        },

        CLOSE_MODAL(state) {
            state.activeModal = null
            state.modalProps = null
            state.error = null
        },

        SET_ERROR(state, error) {
            // Clear any existing timeout
            if (state.errorTimeoutId) {
                clearTimeout(state.errorTimeoutId)
                state.errorTimeoutId = null
            }
            state.error = error
        },

        SET_ERROR_TIMEOUT_ID(state, timeoutId) {
            state.errorTimeoutId = timeoutId
        },

        CLEAR_ERROR(state) {
            state.error = null
            if (state.errorTimeoutId) {
                clearTimeout(state.errorTimeoutId)
                state.errorTimeoutId = null
            }
        }
    },

    actions: {
        openModal({ commit, state }, { modalName, props }) {
            if (state.activeModal) {
                commit('CLOSE_MODAL')
            }
            commit('SET_ACTIVE_MODAL', { modalName, props })
        },

        closeModal({ commit }) {
            commit('CLOSE_MODAL')
        },

        setError({ commit, dispatch }, error) {
            commit('SET_ERROR', error)

            // Set up automatic clearing after 4000ms
            const timeoutId = setTimeout(() => {
                dispatch('clearError')
            }, 4000)

            commit('SET_ERROR_TIMEOUT_ID', timeoutId)
        },

        clearError({ commit }) {
            commit('CLEAR_ERROR')
        },

        // Handle API responses with potential errors
        handleApiResponse({ dispatch }, response) {
            if (response.data.status === 'ERROR') {
                dispatch('setError', response.data.message)
                return false
            }
            dispatch('clearError')
            return true
        },

        // Handle API errors (catch blocks)
        handleApiError({ dispatch }, error) {
            const errorMessage = error.response?.data?.message || 'An unexpected error occurred'
            dispatch('setError', errorMessage)
        }
    },

    getters: {
        isModalOpen: state => modalName => state.activeModal === modalName,
        activeModal: state => state.activeModal,
        modalProps: state => state.modalProps,
        error: state => state.error
    }
}