<template>
  <div class="data-table">
    <table class="data-table__table">
      <thead>
        <tr>
          <th
            v-for="header in headers"
            :key="header.key"
            :class="[
              'data-table__header',
              { 'data-table__header--left': header.align === 'left' },
              { 'data-table__header--center': header.align === 'center' },
              { 'data-table__header--right': header.align === 'right' },
            ]"
          >
            {{ header.label }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(row, index) in paginatedData"
          :key="index"
          class="data-table__row"
        >
          <td
            v-for="header in headers"
            :key="header.key"
            :class="[
              'data-table__cell',
              { 'data-table__cell--left': header.align === 'left' },
              { 'data-table__cell--center': header.align === 'center' },
              { 'data-table__cell--right': header.align === 'right' },
            ]"
          >
            {{ formatCell(row[header.key], header) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    headers: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    perPage: {
      type: Number,
      default: 7,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    formatDate(timestamp) {
      const date = new Date(timestamp * 1000);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear().toString().slice(-2);
      return `${day}.${month}.${year}`;
    },
    formatCell(value, header) {
      if (header.key === "created_at") {
        return this.formatDate(value);
      }
      if (header.formatter) {
        return header.formatter(value);
      }
      return value;
    },
  },
  computed: {
    paginatedData() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.data.slice(start, end);
    },
  },
};
</script>

<style lang="scss" scoped>
.data-table {
  width: 100%;
  overflow-x: auto;
}

.data-table__table {
  width: 100%;
  border-collapse: collapse;
}

.data-table__header {
  font-family: Outfit;
  font-size: 16px;
  font-weight: 700;
  line-height: 20.16px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #a18da1;
  padding-bottom: 16px;
  border-bottom: 2px solid #6b576c;
  padding-right: 12px;
}

.data-table__header:last-child {
  padding-right: 0;
}

.data-table__header--left {
  text-align: left;
}

.data-table__header--center {
  text-align: center;
}

.data-table__header--right {
  text-align: right;
}

.data-table__row {
  border-bottom: 2px dashed #6b576c;
}

.data-table__row:last-child {
  border-bottom: none;
  padding-bottom: 24px;
}

.data-table__cell {
  font-family: Outfit;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.16px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
  padding: 16px 0;
  padding-right: 12px;
  vertical-align: middle;
  text-wrap: nowrap;
}

.data-table__cell:last-child {
  padding-right: 0;
}

.data-table__cell--left {
  text-align: left;
}

.data-table__cell--center {
  text-align: center;
}

.data-table__cell--right {
  text-align: right;
}
</style>
