<template>
  <main class="main page-inside text-page contact-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="contact-container">
            <h1 class="contact-title title">{{ $t("Contact Us") }}</h1>

            <div class="contact-inputs-row">
              <BaseInput
                v-model="form.name"
                :placeholder="$t('Name')"
                :left-icon="require('@/assets/user.svg')"
              />
              <BaseInput
                v-model="form.email"
                type="email"
                :placeholder="$t('Email address')"
                :left-icon="require('@/assets/mail.svg')"
              />
            </div>

            <!-- Message Textarea -->
            <BaseTextarea
              v-model="form.message"
              :placeholder="$t('Message')"
              :left-icon="require('@/assets/chat.svg')"
              :height="206"
              class="contact-message"
            />

            <button class="button contact-submit" @click="submit">
              {{ $t("Send") }}
            </button>

            <transition name="fade">
              <div v-if="successMessage" class="contact-success">
                {{ successMessage }}
              </div>
            </transition>

            <transition name="slide">
              <div v-if="errorMessage" class="contact-error">
                {{ errorMessage }}
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "ContactUs",
  data() {
    return {
      form: {
        name: "",
        email: "",
        message: "",
      },
      successMessage: "",
      errorMessage: "",
    };
  },
  methods: {
    async submit() {
      try {
        const response = await this.$http.post(
          process.env.VUE_APP_API + "contact-us",
          this.form
        );
        if (response.data.status === "OK") {
          this.successMessage = "Thank you, your message has been sent.";
          this.errorMessage = "";
          this.resetForm();
          setTimeout(() => (this.successMessage = ""), 2000);
        } else {
          this.errorMessage = response.data.message;
        }
      } catch (error) {
        this.errorMessage =
          error.response?.data?.message || "Something went wrong!";
      }
    },
    resetForm() {
      this.form.name = "";
      this.form.email = "";
      this.form.message = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-container {
  padding: 46px 105px 32px;
  width: 100%;
  max-width: 1020px;
  margin: 0 auto;
  background: #433144;
  border-radius: 12px;

  @media (max-width: 1024px) {
    padding: 32px 24px;
  }
}

.contact-title {
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 47px;
}

.contact-inputs-row {
  display: flex;
  gap: 24px;
  margin-bottom: 27px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 16px;
  }
}

.contact-message {
  min-height: 200px;
  margin-bottom: 32px;
}

.contact-submit {
  max-width: fit-content;
  margin: 0 auto;
}

.contact-success {
  color: #4caf50;
  text-align: center;
  margin-top: 16px;
}

.contact-error {
  color: #f44336;
  text-align: center;
  margin-top: 16px;
}

// Transitions
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.3s, opacity 0.3s;
}
.slide-enter,
.slide-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
</style>
