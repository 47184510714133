<template>
  <main class="main page-inside faq-page text-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <h1 class="title big">{{ $t("FAQ") }}</h1>
          <div v-if="loading" class="loading">Loading FAQs...</div>
          <div v-else-if="faqs && faqs.length > 0">
            <div v-for="(faq, index) in faqs" :key="index" class="faq-content">
              <h2 class="title">{{ faq.question }}</h2>
              <div class="desc">{{ faq.answer }}</div>
            </div>
          </div>
          <div v-else class="fallback">
            {{ $t("FAQs are currently unavailable.") }}
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "Faq",
  computed: {
    ...mapState("faq", ["faqs", "loading", "error"]),
  },
  methods: {
    ...mapActions("faq", ["fetchFaqs"]),
  },
  created() {
    this.fetchFaqs();
    // this.fetchFaqs(1);
  },
};
</script>

<style scoped lang="scss">
.faq-page {
  .hero-section {
    .wrapper {
      max-width: 1020px;
      padding: 46px 81px 64px;
      background: #433144;
      border-radius: 12px;
      box-shadow: 0 4px 46px rgba(0, 0, 0, 0.12);
      flex-direction: column;

      @media (max-width: 768px) {
        padding: 32px 24px 40px;
      }
    }

    .title.big {
      font-family: "Outfit", sans-serif;
      font-size: 32px;
      font-weight: 800;
      line-height: 1.26;
      text-align: center;
      color: #fff;
      margin-bottom: 46px;

      @media (max-width: 768px) {
        font-size: 28px;
        margin-bottom: 32px;
      }
    }

    .faq-content {
      text-align: center;
      margin-bottom: 46px;

      &:last-child {
        margin-bottom: 0;
      }

      .title {
        font-family: "Outfit", sans-serif;
        font-size: 24px;
        font-weight: 700;
        line-height: 1.26;
        color: #00e5ff;
        margin-bottom: 24px;

        @media (max-width: 768px) {
          font-size: 20px;
          margin-bottom: 16px;
        }
      }

      .desc {
        font-family: "Outfit", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.26;
        text-align: center;
        color: #a18da1;

        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
    }

    .loading,
    .fallback {
      text-align: center;
      color: #fff;
      font-family: "Outfit", sans-serif;
      font-size: 16px;
    }
  }
}
</style>
