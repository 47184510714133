<!-- TextPage.vue -->
<template>
  <main class="main page-inside text-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <transition name="fade">
          <div v-if="isLoading" class="wrapper">
            <div class="loading">Loading...</div>
          </div>
          <div class="wrapper" v-else-if="currentPage">
            <div class="title big">{{ currentPage.title }}</div>
            <div class="content">
              <div class="desc" v-html="currentPage.content"></div>
            </div>
          </div>
          <div v-else-if="error" class="wrapper error-wrapper">
            {{ error }}
          </div>
        </transition>
      </div>
    </div>
  </main>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "TextPage",

  computed: {
    ...mapState("staticPages", ["currentPage", "isLoading", "error", "pages"]),
  },

  created() {
    this.loadPageData();
  },

  watch: {
    "$route.params.identifier": {
      handler(newId) {
        if (newId) {
          this.loadPageData();
        }
      },
    },
  },

  methods: {
    ...mapActions("staticPages", ["fetchPages", "fetchPageContent"]),

    async loadPageData() {
      try {
        if (!this.pages.length) {
          await this.fetchPages();
        }

        const pageId = await this.resolvePageId();
        if (pageId) {
          await this.fetchPageContent(pageId);
        } else {
          this.redirectTo404();
        }
      } catch (error) {
        this.handleError(error);
      }
    },

    async resolvePageId() {
      const identifier = this.$route.params.identifier;
      if (!identifier) {
        this.redirectTo404();
        return null;
      }

      if (!isNaN(identifier)) {
        return Number(identifier);
      }

      let foundPage = null;
      switch (identifier) {
        case "terms":
          foundPage = this.pages.find((p) => p.is_terms === 1);
          break;
        case "privacy":
          foundPage = this.pages.find((p) => p.is_privacy === 1);
          break;
        case "cookie":
          foundPage = this.pages.find((p) =>
            p.title?.toLowerCase().includes("cookie")
          );
          break;
        default:
          foundPage = this.pages.find((p) => p.slug === identifier);
      }

      return foundPage?.id || null;
    },

    redirectTo404() {
      if (this.$route.name !== "PageNotFound") {
        this.$router.push({
          name: "PageNotFound",
          params: { title: "PageNotFound" },
        });
      }
    },

    handleError(error) {
      console.error("TextPage Error:", error);
      if (error.response?.status === 401) {
        this.$emit("auth-error");
      } else if (error.response?.status === 404) {
        this.redirectTo404();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.text-page {
  .hero-section {
    .wrapper {
      max-width: 1020px;
      padding: 46px 81px 64px;
      background: #433144;
      border-radius: 12px;
      box-shadow: 0 4px 46px rgba(0, 0, 0, 0.12);

      @media (max-width: 768px) {
        padding: 32px 24px 40px;
      }
    }

    .title.big {
      font-family: "Outfit", sans-serif;
      text-transform: uppercase;
      font-size: 32px;
      font-weight: 800;
      line-height: 1.26;
      text-align: center;
      color: #fff;
      margin-bottom: 46px;

      @media (max-width: 768px) {
        font-size: 28px;
        margin-bottom: 32px;
      }
    }

    .content {
      text-align: left;

      ::v-deep {
        strong {
          font-weight: 700;
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-family: "Outfit", sans-serif;
          font-size: 16px;
          font-weight: 700;
          line-height: 20px;
          text-align: left;
          color: #ffffff;
        }

        p {
          font-family: "Outfit", sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
          color: #a18da1;
        }

        a {
          color: #00e5ff;
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
