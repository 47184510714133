<template>
  <div class="modal offer-modal ready-modal-products">
    <div class="overlay" @click="$parent.closeAddExistingIslandModal"></div>
    <div class="wrapper">
      <img
        class="close"
        @click="$parent.closeAddExistingIslandModal"
        src="./../assets/close.svg"
      />
      <div class="container">
        <div class="form-wrapper" v-if="!$parent.successMessage">
          <div class="title">{{ $t("Add existing island") }}</div>
          <BaseInput
            v-model="host"
            type="text"
            :label="$t('Host:')"
            class="modal-text-input"
          />

          <BaseInput
            v-model="port"
            type="text"
            :label="$t('Port:')"
            class="modal-text-input"
          />
          <button
            class="button secondary"
            :disabled="!port || !host"
            @click="$parent.addExistingIsland"
          >
            {{ $t("Buy") }}
          </button>
          <transition name="fade">
            <div class="desc red" v-if="$parent.error">{{ $parent.error }}</div>
          </transition>
        </div>
        <transition name="fade">
          <div v-if="$parent.successMessage">
            <div class="title green">{{ $parent.successMessage }}</div>
            <button class="button" @click="$parent.closeAddExistingIslandModal">
              {{ $t("Ok") }}
            </button>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReadyModalProducts",
  props: [],
  components: {},
  data: function () {
    return {
      host: "",
      port: "",
    };
  },
  watch: {
    host() {
      this.$emit("hostChange", this.host);
    },
    port() {
      this.$emit("portChange", this.port);
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.title {
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 51px;
}

::v-deep(.modal-text-input) {
  text-align: center;

  input {
    text-align: center;
  }
}

.modal-text-input + .modal-text-input {
  margin-top: 24px;
}

.button.secondary {
  margin-top: 46px;
  max-width: 185px;
}
</style>
