import axios from "axios";
// import store from "@/store";
// import router from "./router";

const instance = axios.create({
  baseURL: process.env.VUE_APP_API,
  withCredentials: true,
  timeout: 5000, // set the default timeout for requests to 5 seconds
});


// Axios 401 error handling
// instance.interceptors.response.use(
//   response => response,
//   error => {
//     if (error.response && error.response.status === 401) {
//       // If a 401 error occurs, open the login modal and redirect to home
//       store.dispatch('auth/logout');
//       store.dispatch('modals/openModal', { modalName: 'login' });

//       router.push('/home'); // Redirect to home page
//     }
//     return Promise.reject(error);
//   }
// );

// instance.interceptors.request.use(
//   function (config) {
//     const lang_id = store.getters["app/currentLanguage"];

//     // List of URLs to exclude
//     const excludeUrls = ["/items/list"];

//     // Check if the URL is not in the exclude list
//     if (!excludeUrls.includes(config.url)) {
//       if (config.method === "get") {
//         config.params = {
//           ...config.params,
//         };
//       } else if (["post", "put", "patch", "POST", "PUT", "PATCH"].includes(config.method)) {
//         config.data = {
//           ...config.data,
//           lang_id,
//         };
//       }
//     }

//     return config;
//   },
//   function (error) {
//     // Do something with request error
//     return Promise.reject(error);
//   }
// );

export default instance;
