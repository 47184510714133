<template>
  <div class="pagination">
    <button
      class="pagination__button"
      @click="prevPage"
      :disabled="currentPage === 1"
    >
      <img src="@/assets/arrow-left.svg" alt="Previous" />
    </button>
    <span class="pagination__text">
      {{ startItem }}-{{ endItem }} of {{ totalItems }}
    </span>
    <button
      class="pagination__button"
      @click="nextPage"
      :disabled="currentPage === totalPages"
    >
      <img src="@/assets/arrow-right.svg" alt="Next" />
    </button>
  </div>
</template>

<script>
export default {
  props: {
    totalItems: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      default: 7,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalItems / this.perPage);
    },
    startItem() {
      return (this.currentPage - 1) * this.perPage + 1;
    },
    endItem() {
      let end = this.currentPage * this.perPage;
      return end > this.totalItems ? this.totalItems : end;
    },
  },
  methods: {
    prevPage() {
      if (this.currentPage > 1) {
        this.$emit("update:currentPage", this.currentPage - 1);
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.$emit("update:currentPage", this.currentPage + 1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
}

.pagination__button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
}

.pagination__button:disabled {
  opacity: 0.5;
  cursor: default;
}

.pagination__button img {
  width: 24px;
  height: 24px;
}

.pagination__text {
  font-family: Outfit;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.16px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
  margin: 0 16px;
}
</style>
