<!-- AddIslandModal.vue -->
<template>
  <BaseModal @close="$emit('close')" size="medium">
    <div class="add-island-modal">
      <h1 class="add-island-modal__title">Add existing island</h1>

      <form @submit.prevent="handleSubmit" class="add-island-modal__form">
        <BaseInput v-model="host" placeholder="Host" label="Host:" />

        <BaseInput v-model="port" placeholder="Port" label="Port:" />

        <button class="button secondary">Buy</button>
      </form>
    </div>
  </BaseModal>
</template>

<script>
export default {
  name: "AddIslandModal",
  data() {
    return {
      host: "",
      port: "",
    };
  },
  methods: {
    handleSubmit() {
      // Handle form submission
    },
  },
};
</script>
