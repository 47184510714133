<template>
  <div class="base-form-field">
    <label v-if="label" class="base-form-label">
      <div class="base-form-label-text">{{ label }}</div>
      <div class="base-form-input-wrapper">
        <slot name="icon-left">
          <img
            v-if="leftIcon"
            :src="leftIcon"
            class="base-form-icon base-form-icon-left"
            :alt="label || 'Input icon'"
          />
        </slot>
        <input
          :type="type"
          :value="value"
          @input="updateValue($event.target.value)"
          class="base-form-input"
          :class="{
            'base-form-input-with-left-icon': $slots['icon-left'] || leftIcon,
            'base-form-input-with-right-icon':
              $slots['icon-right'] || rightIcon,
          }"
          v-bind="$attrs"
        />
        <slot name="icon-right">
          <img
            v-if="rightIcon"
            :src="rightIcon"
            class="base-form-icon base-form-icon-right"
            :alt="label || 'Input icon'"
            :style="{ cursor: rightIconClickable ? 'pointer' : 'pointer' }"
            @click="handleRightIconClick"
          />
        </slot>
      </div>
    </label>
    <div v-else class="base-form-input-wrapper">
      <slot name="icon-left">
        <img
          v-if="leftIcon"
          :src="leftIcon"
          class="base-form-icon base-form-icon-left"
          :alt="'Input icon'"
        />
      </slot>
      <input
        :type="type"
        :value="value"
        @input="updateValue($event.target.value)"
        class="base-form-input"
        :class="{
          'base-form-input-with-left-icon': $slots['icon-left'] || leftIcon,
          'base-form-input-with-right-icon': $slots['icon-right'] || rightIcon,
        }"
        v-bind="$attrs"
      />
      <slot name="icon-right">
        <img
          v-if="rightIcon"
          :src="rightIcon"
          class="base-form-icon base-form-icon-right"
          :alt="'Input icon'"
          :style="{ cursor: rightIconClickable ? 'pointer' : 'default' }"
          @click="handleRightIconClick"
        />
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseInput",
  props: {
    value: {
      type: [String, Number],
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    leftIcon: {
      type: String,
      default: "",
    },
    rightIcon: {
      type: String,
      default: "",
    },
    rightIconClickable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    updateValue(value) {
      this.$emit("input", value);
    },
    handleRightIconClick() {
      this.$emit("right-icon-click");
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.base-form-field {
  width: 100%;
}

.base-form-label {
  display: block;
  width: 100%;
}

.base-form-label-text {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: #a18da1;
  padding: 0 14px 8px;
}

.base-form-input-wrapper {
  position: relative;
  width: 100%;
}

.base-form-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  pointer-events: none;
}

.base-form-icon-right[style*="pointer"] {
  pointer-events: auto; // Specifically override for clickable icons
}

.base-form-icon-left {
  left: 16px;
}

.base-form-icon-right {
  right: 16px;
}

.base-form-input {
  width: 100%;
  background: #271a28;
  border: 1px solid #6b576c;
  border-radius: 20px;
  font-family: inherit;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #fff;
  outline: 0;
  padding: 7px 14px;
  transition: border-color 0.2s;

  &::placeholder {
    color: #a18da1;
  }

  &:focus {
    border-color: #8b728c;
  }
}

.base-form-input-with-left-icon {
  padding-left: 54px;
}

.base-form-input-with-right-icon {
  padding-right: 54px;
}

.base-form-input[type="number"] {
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
