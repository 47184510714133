var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"personal-information"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("Contact information")))]),_c('div',{staticClass:"grid"},[_c('div',{staticClass:"column"},[_c('BaseInput',{attrs:{"label":"First name: *"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('BaseInput',{attrs:{"type":"email","label":"E-mail: *"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('BaseInput',{attrs:{"label":"Nickname on Minecraft server: *"},model:{value:(_vm.nickname),callback:function ($$v) {_vm.nickname=$$v},expression:"nickname"}})],1),_c('div',{staticClass:"column"},[_c('BaseInput',{attrs:{"label":"Last name: *"},model:{value:(_vm.surname),callback:function ($$v) {_vm.surname=$$v},expression:"surname"}}),_c('BaseInput',{attrs:{"label":"Phone: *"},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('button',{staticClass:"button",on:{"click":_vm.updateProfileData}},[_vm._v(" "+_vm._s(_vm.$t("Update"))+" ")])],1)]),_c('div',{staticClass:"error-message",class:{
      error: _vm.profileUpdateErrorMessaage,
      success: _vm.profileUpdateSuccessMessaage,
    }},[_vm._v(" "+_vm._s(_vm.profileUpdateErrorMessaage || _vm.profileUpdateSuccessMessaage)+" ")]),_c('div',{staticClass:"separator"}),_c('div',{staticClass:"grid"},[_c('div',{staticClass:"column"},[_c('BaseInput',{attrs:{"type":"password","label":"Current password: *"},model:{value:(_vm.curPass),callback:function ($$v) {_vm.curPass=$$v},expression:"curPass"}}),_c('BaseInput',{attrs:{"type":"password","label":"Confirm password: *"},model:{value:(_vm.repeatPass),callback:function ($$v) {_vm.repeatPass=$$v},expression:"repeatPass"}})],1),_c('div',{staticClass:"column"},[_c('BaseInput',{attrs:{"type":"password","label":"New password: *"},model:{value:(_vm.newPass),callback:function ($$v) {_vm.newPass=$$v},expression:"newPass"}}),_c('button',{staticClass:"button",on:{"click":_vm.updatePassword}},[_vm._v(" "+_vm._s(_vm.$t("Update"))+" ")])],1)]),_c('div',{staticClass:"error-message",class:{
      error: _vm.passUpdateErrorMessaage,
      success: _vm.passUpdateSuccessMessaage,
    }},[_vm._v(" "+_vm._s(_vm.passUpdateErrorMessaage || _vm.passUpdateSuccessMessaage)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }