<template>
  <div class="option-card" v-if="item">
    <!-- Top Block: Background with Image -->
    <div class="option-card-top">
      <div class="option-image-wrapper">
        <img
          :src="imgDomain + item.image_url"
          :alt="item.title"
          class="option-image"
        />
      </div>
    </div>
    <!-- Bottom Block: Title and Price -->
    <div class="option-card-bottom">
      <h4 class="option-title" :title="$t(item.title)">{{ $t(item.title) }}</h4>
      <p class="option-price">
        {{ item.price }}<span class="currency">{{ currency }}</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "OptionCard",
  props: ["item", "currency"],
  data: function () {
    return {
      imgDomain: "",
    };
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
  },
};
</script>

<style lang="scss">
.option-card {
  width: 226px;
  border-radius: 18px;
  box-shadow: 0px 4px 46px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
}

/* Top Block with Background Image */
.option-card-top {
  padding: 43px;
  width: 100%;
  background-image: url("../../src/assets/optionBg.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  display: flex;
  align-items: center;
  justify-content: center;
}

.option-image-wrapper {
  width: 140px;
  height: 140px;
}

.option-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* Bottom Block with Title and Price */
.option-card-bottom {
  padding: 19px 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.option-title {
  font-family: Outfit;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 60%;
}

.option-price {
  font-family: Outfit;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: right;
  color: #00e5ff;
}
</style>
