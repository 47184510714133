<template>
  <div class="personal-information">
    <div class="title">{{ $t("Contact information") }}</div>
    <div class="grid">
      <div class="column">
        <BaseInput v-model="name" label="First name: *" />
        <BaseInput v-model="email" type="email" label="E-mail: *" />
        <BaseInput v-model="nickname" label="Nickname on Minecraft server: *" />
      </div>
      <div class="column">
        <BaseInput v-model="surname" label="Last name: *" />
        <BaseInput v-model="phone" label="Phone: *" />
        <button class="button" @click="updateProfileData">
          {{ $t("Update") }}
        </button>
      </div>
    </div>

    <div
      class="error-message"
      :class="{
        error: profileUpdateErrorMessaage,
        success: profileUpdateSuccessMessaage,
      }"
    >
      {{ profileUpdateErrorMessaage || profileUpdateSuccessMessaage }}
    </div>

    <div class="separator"></div>
    <div class="grid">
      <div class="column">
        <BaseInput
          v-model="curPass"
          type="password"
          label="Current password: *"
        />
        <BaseInput
          v-model="repeatPass"
          type="password"
          label="Confirm password: *"
        />
      </div>
      <div class="column">
        <BaseInput v-model="newPass" type="password" label="New password: *" />
        <button class="button" @click="updatePassword">
          {{ $t("Update") }}
        </button>
      </div>
    </div>

    <div
      class="error-message"
      :class="{
        error: passUpdateErrorMessaage,
        success: passUpdateSuccessMessaage,
      }"
    >
      {{ passUpdateErrorMessaage || passUpdateSuccessMessaage }}
    </div>
  </div>
</template>

<script>
import BaseInput from "@/components/BaseInput.vue";

export default {
  components: {
    BaseInput,
  },
  data() {
    return {
      email: "",
      name: "",
      surname: "",
      phone: "",
      nickname: "",
      curPass: "",
      newPass: "",
      repeatPass: "",
      profileUpdateSuccessMessaage: "",
      profileUpdateErrorMessaage: "",
      passUpdateSuccessMessaage: "",
      passUpdateErrorMessaage: "",
    };
  },
  computed: {
    user() {
      return this.$store.getters["auth/user"];
    },
  },
  watch: {
    user: {
      immediate: true,
      handler(user) {
        if (user) {
          this.email = user.email || "";
          this.name = user.name || "";
          this.surname = user.surname || "";
          this.phone = user.phone || "";
          this.nickname = user.nickname || "";
        }
      },
    },
  },
  mounted() {
    this.fetchProfile();
  },
  methods: {
    async fetchProfile() {
      try {
        await this.$store.dispatch("auth/fetchUserProfile");
      } catch (error) {
        console.error("Failed to fetch profile:", error);
      }
    },
    async updateProfileData() {
      const profileData = {
        email: this.email,
        name: this.name,
        surname: this.surname,
        phone: this.phone,
        nickname: this.nickname,
      };
      try {
        await this.$store.dispatch("auth/updateProfileData", profileData);
        this.profileUpdateSuccessMessaage = "Profile updated successfully!";
        this.profileUpdateErrorMessaage = "";
        setTimeout(() => {
          this.profileUpdateSuccessMessaage = "";
        }, 3000);
      } catch (error) {
        this.profileUpdateSuccessMessaage = "";
        this.profileUpdateErrorMessaage =
          error?.response?.data?.message || "Failed to update profile";
        setTimeout(() => {
          this.profileUpdateErrorMessaage = "";
        }, 3000);
      }
    },
    updatePassword() {
      let passwordList = {
        cur_password: this.curPass,
        new_password: this.newPass,
        repeat_password: this.repeatPass,
      };
      this.$http
        .post(process.env.VUE_APP_API + "user/change-password", passwordList)
        .then((res) => {
          if (res.data.status === "OK") {
            this.passUpdateErrorMessaage = "";
            this.passUpdateSuccessMessaage = "Success";
            this.curPass = "";
            this.newPass = "";
            this.repeatPass = "";
            setTimeout(() => {
              this.passUpdateSuccessMessaage = "";
            }, 3000);
          }
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignIn();
          }
          this.passUpdateSuccessMessaage = "";
          this.passUpdateErrorMessaage = res.response.data.message;
          setTimeout(() => {
            this.passUpdateErrorMessaage = "";
          }, 3000);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.personal-information {
  .title {
    font-family: Outfit;
    font-size: 32px;
    font-weight: 800;
    line-height: 40.32px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-bottom: 29px;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 24px 16px;
  }

  .column {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .button {
      margin-top: 28px;
      //   max-width: 313px;
    }
  }

  .separator {
    width: 100%;
    height: 0;
    border: 2px dashed #6b576c;
    margin: 36px 0;
  }

  .message {
    margin-top: 10px;
    text-align: center;

    &.error {
      color: red;
    }
  }
}
</style>
