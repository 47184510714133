<template>
  <button
    type="button"
    class="sound-toggle"
    :class="{
      'sound-toggle--active': isSoundOn,
      'home-page': $route.name == 'Home' || $route.path == '/',
    }"
    @click="toggleSound"
    :aria-label="isSoundOn ? 'Mute sound' : 'Unmute sound'"
  >
    <IconSound :is-muted="isSoundOn" :size="64" color="#FFFFFF" />
  </button>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import IconSound from "./icons/IconSound.vue";

export default {
  name: "SoundToggle",
  components: {
    IconSound,
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {
    ...mapGetters("sound", ["isEnabled"]),
    isSoundOn() {
      return this.isEnabled;
    },
  },
  methods: {
    ...mapActions("sound", ["toggle"]),
    toggleSound() {
      this.toggle();
    },
  },
};
</script>

<style lang="scss">
.sound-toggle.home-page {
  position: relative;
  bottom: initial;
  right: initial;
  top: initial;
  transform: none;
}

.sound-toggle {
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: opacity 0.2s ease;

  @media (min-width: 1024px) {
    position: absolute;
    top: -54px;
    transform: translateY(-100%);
    right: 0;
  }

  @media (max-width: 1024px) {
    position: fixed;
    bottom: 0;
    top: initial;
  }

  &:hover {
    opacity: 0.8;
  }

  &--active {
    opacity: 1;
  }
}
</style>
