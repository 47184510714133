<template>
  <div
    class="mini-product-card"
    :class="{ 'is-hovered': isHovered, 'is-chosen': isSelected }"
    @mouseenter="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <div class="image-wrapper">
      <img :src="imgDomain + item.image_url" :alt="item.title" class="image" />
      <img
        v-if="isSelected"
        src="@/assets/choose.svg"
        alt="Chosen"
        class="tick"
      />
    </div>
    <div class="title">
      {{ item.title }}
    </div>
    <div class="dimensions">
      {{ item.dimensions }}
    </div>
  </div>
</template>

<script>
export default {
  name: "MiniProductCard",
  props: {
    item: {
      type: Object,
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imgDomain: process.env.VUE_APP_STATIC_DOMAIN,
      isHovered: false,
    };
  },
};
</script>
LLM served by Perplexity Labs Here is the rewritten code with normal selectors
instead of SCSS: xml
<template>
  <div
    class="mini-product-card"
    :class="{ 'is-hovered': isHovered, 'is-chosen': isSelected }"
    @mouseenter="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <div class="image-wrapper">
      <img :src="imgDomain + item.image_url" :alt="item.title" class="image" />
      <img
        v-if="isSelected"
        src="@/assets/choose.svg"
        alt="Chosen"
        class="tick"
      />
    </div>
    <div class="title">
      {{ item.title }}
    </div>
    <div class="dimensions">
      {{ item.dimensions }}
    </div>
  </div>
</template>

<script>
export default {
  name: "MiniProductCard",
  props: {
    item: {
      type: Object,
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imgDomain: process.env.VUE_APP_STATIC_DOMAIN,
      isHovered: false,
    };
  },
};
</script>

<style>
.mini-product-card {
  width: 124px;
  cursor: pointer;
}

.mini-product-card .image-wrapper {
  width: 124px;
  height: 99px;
  border-radius: 12px;
  /* overflow: hidden; */
  position: relative;
  margin-bottom: 24px;
}

.mini-product-card .image-wrapper .image {
  box-sizing: border-box;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mini-product-card .image-wrapper .tick {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(50%);
  width: 46px;
  height: 46px;
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.mini-product-card .title {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.26;
  text-align: center;
  color: #ffffff;
  margin-bottom: 8px;
}

.mini-product-card .dimensions {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.26;
  text-align: center;
  color: #c65e7f;
}

.mini-product-card.is-hovered .image-wrapper .image,
.mini-product-card.is-chosen .image-wrapper .image {
  border: 2px solid #00e5ff;
}

.mini-product-card.is-hovered .image-wrapper .tick,
.mini-product-card.is-chosen .image-wrapper .tick {
  opacity: 1;
  transform: translateX(-50%) translateY(50%);
}

.mini-product-card.is-hovered .title,
/* .mini-product-card.is-hovered .dimensions, */
.mini-product-card.is-chosen .title,
.mini-product-card.is-chosen .dimensions {
  color: #00e5ff;
}
</style>
