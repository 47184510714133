<template>
  <BaseModal @close="handleClose" size="small">
    <div class="forgot-password-modal">
      <h1 class="forgot-password-modal__title">Forgot Password</h1>

      <form @submit.prevent="handleSubmit" class="forgot-password-modal__form">
        <BaseInput
          v-model="email"
          type="email"
          placeholder="Email address"
          :left-icon="require('@/assets/mail.svg')"
          class="forgot-password-modal__input"
        />

        <div class="forgot-password-modal__error" v-if="error">
          {{ error }}
        </div>
        <div class="forgot-password-modal__success" v-else-if="successMessage">
          {{ successMessage }}
        </div>

        <button class="button forgot-password-modal__button">
          Reset Password
        </button>

        <div class="forgot-password-modal__login">
          <span>Have an account?</span>
          <a href="#" @click.prevent="openLogin">Back to Login</a>
        </div>
      </form>
    </div>
  </BaseModal>
</template>

<script>
export default {
  name: "ForgotPasswordModal",
  props: {
    error: {
      type: String,
      default: "",
    },
    successMessage: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      email: "",
    };
  },
  methods: {
    handleSubmit() {
      let data = {
        email: this.email,
      };
      this.$emit("submit", data);
    },
    openLogin() {
      this.$emit("open-login");
    },
    handleClose() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.forgot-password-modal {
  padding: 46px 120px 32px;
}

.forgot-password-modal__error {
  color: #ff4444;
  font-size: 0.875rem;
}

.forgot-password-modal__success {
  color: #00ff00;
  font-size: 0.875rem;
}

.forgot-password-modal__title {
  font-size: 32px;
  font-weight: 800;
  line-height: 40px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.forgot-password-modal__form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.forgot-password-modal__input {
  margin-top: 24px;
}

.forgot-password-modal__button {
  margin: 0 auto;
}

.forgot-password-modal__login {
  margin-top: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.forgot-password-modal__login span {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #ffffff;
}

.forgot-password-modal__login a {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #00e5ff;
  text-decoration: none;
  transition: opacity 0.3s;
}

.forgot-password-modal__login a:hover {
  opacity: 0.8;
}

@media (max-width: 768px) {
  .forgot-password-modal {
    padding: 32px;
  }
}
</style>
