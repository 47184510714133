<template>
  <footer class="footer">
    <div class="wrapper footer_content">
      <SoundToggle v-if="showSoundToggle" />

      <!-- Logo Column -->
      <div class="footer_logo">
        <a href="/" class="footer_logo_link">
          <span class="footer_logo_text">cubes</span>
          <span class="footer_logo_text footer_logo_text-accent">couture</span>
        </a>

        <!-- Social Links -->
        <ul class="social_links" v-if="socialLinks && socialLinks.length">
          <li
            v-for="link in socialLinks"
            :key="link.title"
            class="social_link_item"
          >
            <a
              :href="link.link"
              target="_blank"
              rel="noopener noreferrer"
              class="social_link"
            >
              <img :src="link.img" :alt="link.title" class="social_link_icon" />
            </a>
          </li>
        </ul>
      </div>

      <!-- Navigation Links -->
      <nav class="footer_nav">
        <ul class="footer_nav_list">
          <li v-for="item in $parent.nav" :key="item.path">
            <router-link :to="item.path" class="footer_nav_link">
              {{ $t(item.title) }}
            </router-link>
          </li>
        </ul>
      </nav>

      <!-- Static Pages Links -->
      <div class="footer_terms" v-if="staticPages.length">
        <ul class="footer_terms_list">
          <li v-for="page in staticPages" :key="page.id">
            <router-link :to="`/pages/${page.slug}`" class="footer_terms_link">
              {{ page.title }}
            </router-link>
          </li>
        </ul>
      </div>

      <!-- Email Section -->
      <div class="footer_policy">
        <ul class="footer_policy_list">
          <li>
            <a
              :href="`mailto:${$parent.settings.support_email}`"
              class="footer_policy_link"
            >
              {{ $parent.settings.support_email }}
            </a>
          </li>
        </ul>
      </div>

      <!-- Payment Methods -->
      <div class="footer_payment">
        <ul class="footer_payment_list">
          <li v-for="method in paymentMethods" :key="method.name">
            <img
              :src="require(`@/assets/${method.icon}`)"
              :alt="method.name"
              class="footer_payment_icon"
            />
          </li>
        </ul>
      </div>
    </div>

    <!-- Copyright Section -->
    <div class="wrapper footer_bottom">
      <p class="footer_copyright" v-if="$parent.settings.copyright">
        © {{ new Date().getFullYear() }} {{ $parent.settings.copyright }}
      </p>
      <div
        v-if="$parent.settings.requisites"
        class="footer_disclaimer"
        v-html="$parent.settings.requisites"
      ></div>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Footer",

  components: {},

  props: {
    socialLinks: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      paymentMethods: [
        { name: "Visa", icon: "visa.svg" },
        { name: "Mastercard", icon: "mastercard.svg" },
        { name: "3D Secure", icon: "3ds.png" },
      ],
    };
  },

  computed: {
    ...mapGetters("staticPages", ["staticPages"]),

    showSoundToggle() {
      const excludedRoutes = ["Home", "ContactUs", "Faq"];
      const excludedPaths = ["/", "/home"];

      return (
        !excludedRoutes.includes(this.$route.name) &&
        !excludedPaths.includes(this.$route.path)
      );
    },
  },

  // async created() {
  //   // Ensure static pages are loaded
  //   if (!this.staticPages.length) {
  //     try {
  //       await this.$store.dispatch("staticPages/fetchPages");
  //     } catch (error) {
  //       console.error("Failed to load static pages:", error);
  //     }
  //   }
  // },
};
</script>

<style lang="scss" scoped>
.footer {
  padding: 54px 16px;
  color: #fff;
}

.footer_content {
  position: relative;
  display: grid;
  grid-template-columns: 190px 110px repeat(2, auto) 190px;
  gap: 90px;
  margin-bottom: 48px;
  text-wrap: nowrap;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 24px;
  }
}

.footer_logo {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;

  &_link {
    text-decoration: none;
  }

  &_text {
    font-family: "Outfit", sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.019em;
    color: #fff;

    &-accent {
      color: #c65e7f;
    }
  }
}

.social_links {
  display: flex;
  flex-direction: row;
  gap: 12px;
  list-style: none;
  padding: 0;
  margin: 16px 0 0;

  .social_link_item {
    display: inline-block;

    .social_link {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      background-color: #fff;
      border-radius: 50%;
      overflow: hidden;
      text-decoration: none;

      .social_link_icon {
        width: 100%;
        height: auto;
      }
    }
  }
}

.footer_nav,
.footer_terms,
.footer_policy {
  &_list {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &_link {
    font-family: "Outfit", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -0.019em;
    text-decoration: none;
    color: #fff;
    display: inline-block;
    cursor: pointer;
  }
}

.footer_terms_link,
.footer_policy_link {
  color: #c65e7f;
}

.footer_payment {
  &_list {
    display: flex;
    gap: 17px;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &_icon {
    height: 24px;
    width: auto;
  }
}

.footer_bottom {
  margin-top: 43px;
  display: flex;
  align-items: center;
  text-align: center;
  gap: 16px;
}

.footer_copyright {
  // position: absolute;
  font-family: "Outfit", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: -0.019em;
  color: rgba(255, 255, 255, 0.75);
  margin: 0;
}

.footer_disclaimer {
  font-family: "Outfit", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: -0.019em;
  color: #857f86;
  max-width: 800px;
  margin: 0 auto;

  :deep(a) {
    color: #00e5ff;
    text-decoration: underline;
  }
}

@media (max-width: 768px) {
  .footer {
    padding: 32px 16px;
  }

  .footer_content {
    gap: 24px;
  }

  .footer_payment_list {
    justify-content: center;
  }

  .footer_bottom {
    flex-direction: column;
  }
}
</style>
