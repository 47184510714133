import axios from 'axios';

export default {
    namespaced: true,

    state: {
        pages: [],
        currentPage: null,
        isLoading: false,
        error: null
    },

    getters: {
        staticPages: (state) => state.pages,

        getPageBySlug: (state) => (slug) => {
            return state.pages.find(page => page.slug === slug);
        },

        getPageById: (state) => (id) => {
            return state.pages.find(page => page.id === parseInt(id));
        },

        getTermsPage: (state) => {
            return state.pages.find(page => page.is_terms === 1);
        },

        getPrivacyPage: (state) => {
            return state.pages.find(page => page.is_privacy === 1);
        },

        getCookiePage: (state) => {
            return state.pages.find(page => page.title.toLowerCase().includes('cookie'));
        }
    },

    mutations: {
        SET_PAGES(state, pages) {
            state.pages = pages;
        },
        SET_CURRENT_PAGE(state, page) {
            state.currentPage = page;
        },
        SET_LOADING(state, status) {
            state.isLoading = status;
        },
        SET_ERROR(state, error) {
            state.error = error;
        },
        CLEAR_ERROR(state) {
            state.error = null;
        }
    },

    actions: {
        async fetchPages({ commit }) {
            commit('SET_LOADING', true);
            commit('CLEAR_ERROR');

            try {
                const response = await axios.get(`${process.env.VUE_APP_API}static-pages`);
                if (response.data.status === 'OK' && Array.isArray(response.data.payload)) {
                    commit('SET_PAGES', response.data.payload);
                } else {
                    throw new Error('Invalid response format');
                }
            } catch (error) {
                const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch pages';
                commit('SET_ERROR', errorMessage);
                throw error;
            } finally {
                commit('SET_LOADING', false);
            }
        },

        async fetchPageContent({ commit }, identifier) {
            commit('SET_LOADING', true);
            commit('CLEAR_ERROR');

            try {
                const response = await axios.get(`${process.env.VUE_APP_API}static-pages/${identifier}`);
                if (response.data.status === 'OK' && response.data.payload) {
                    commit('SET_CURRENT_PAGE', response.data.payload);
                    return response.data.payload;
                }
                throw new Error('Invalid response format');
            } catch (error) {
                const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch page content';
                commit('SET_ERROR', errorMessage);
                throw error;
            } finally {
                commit('SET_LOADING', false);
            }
        }
    }
};