<template>
  <BaseModal @close="handleClose" size="medium">
    <div class="login-modal">
      <h1 class="login-modal__title">Log in</h1>
      <p class="login-modal__subtitle">to continue</p>

      <form @submit.prevent="handleSubmit" class="login-modal__form">
        <BaseInput
          v-model="formData.email"
          :error="validationErrors.email"
          type="email"
          placeholder="Email address"
          class="login-modal__input"
          :left-icon="require('@/assets/mail.svg')"
          @input="clearError('email')"
        />

        <BaseInput
          v-model="formData.password"
          :error="validationErrors.password"
          :type="showPassword ? 'text' : 'password'"
          placeholder="Password"
          class="login-modal__input"
          :left-icon="require('@/assets/key.svg')"
          :right-icon="require('@/assets/eye.svg')"
          :rightIconClickable="true"
          @right-icon-click="togglePassword"
          @input="clearError('password')"
        />

        <div class="login-modal__error" v-if="error">
          {{ error }}
        </div>

        <div class="login-modal__forgot">
          <a href="#" @click.prevent="openForgotPassword">
            Forgot your password?
          </a>
        </div>

        <button class="button login-modal__button">
          {{ "Log in" }}
        </button>

        <div class="login-modal__divider">OR</div>

        <button
          type="button"
          class="login-modal__google"
          @click="redirectGoogleLogin"
        >
          <img
            src="@/assets/google.svg"
            alt="Google"
            class="login-modal__google-icon"
          />
          Continue with Google
        </button>

        <div class="login-modal__signup">
          <span>Don't have an account?</span>
          <a href="#" @click.prevent="openSignup">Sign Up</a>
        </div>
      </form>
    </div>
  </BaseModal>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "LoginModal",
  components: {},
  props: {
    error: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      formData: {
        email: "",
        password: "",
      },
      validationErrors: {
        email: "",
        password: "",
      },
      showPassword: false,
      googleUser: null,
    };
  },

  computed: {
    // ...mapGetters("auth", ["loading"]),
  },

  methods: {
    // ...mapActions("auth", ["login"]),
    ...mapActions("modals", ["closeModal"]),

    clearError(field) {
      if (field) {
        this.validationErrors[field] = "";
      }
      // this.error = "";
      this.$emit("clearError");
    },

    async handleSubmit() {
      const credentials = {
        username: this.formData.email,
        password: this.formData.password,
      };

      this.$emit("submit", credentials);
      //   const result = await this.login(credentials);

      //   if (result.success) {
      //     this.handleClose();
      //     this.$router.push("/profile/my-islands");
      //   } else {
      //     this.error = result.error;
      //   }
    },

    handleClose() {
      this.formData = { email: "", password: "" };
      this.validationErrors = { email: "", password: "" };
      this.clearError();
      this.closeModal();
    },

    togglePassword() {
      this.showPassword = !this.showPassword;
    },

    openForgotPassword() {
      this.$emit("open-forgot-password");
    },

    openSignup() {
      this.$emit("open-signup");
    },

    redirectGoogleLogin() {
      window.location.href = `${process.env.VUE_APP_API}auth/google/redirect`;
    },
  },
};
</script>

<style lang="scss" scoped>
/* Login Modal Styles */
.login-modal {
  //   max-width: 355px;
  margin: 0 auto;
  padding: 46px 120px 32px;
}

.login-modal__error {
  color: #e53e3e;
  padding: 0.75rem;
  border-radius: 0.375rem;
  margin-bottom: 1rem;
  font-size: 0.875rem;
}

.login-modal__title {
  font-size: 32px;
  font-weight: 800;
  line-height: 40px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.login-modal__subtitle {
  font-size: 20px;
  font-weight: 200;
  line-height: 25.2px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 46px;
}

.login-modal__form {
  display: flex;
  flex-direction: column;
  //   gap: 24px;
}

.login-modal__input + .login-modal__input {
  margin-top: 24px;
}

.login-modal__forgot {
  text-align: left;
  margin: 16px auto 16px;
}

.login-modal__forgot a {
  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.019em;
  color: #c65e7f;
  text-decoration: none;
  transition: opacity 0.3s;
}

.login-modal__forgot a:hover {
  opacity: 0.8;
}

.login-modal__button {
  margin: 0 auto;
  max-width: 200px;
}

.login-modal__divider {
  font-size: 14px;
  font-weight: 200;
  line-height: 40px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  margin: 20px 0 25px;
  position: relative;
}

/* .login-modal__divider::before {
  content: '';
  position: absolute;
  top: 50%;
  width: calc(50% - 40px);
  height: 1px;
  background: rgba(255, 255, 255, 0.1);
}

.login-modal__divider::after {
  content: '';
  position: absolute;
  top: 50%;
  width: calc(50% - 40px);
  height: 1px;
  background: rgba(255, 255, 255, 0.1);
  right: 0;
} */

.login-modal__google {
  // width: 284px;
  width: 100%;
  max-width: 200px;
  height: 40px;
  background: #ffffff;
  border-radius: 6px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 6px 14px;
  cursor: pointer;
  transition: background 0.3s;
  color: #1e2330;
}

.login-modal__google:hover {
  background: #f5f5f5;
}

.login-modal__google-icon {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}

.login-modal__google-text {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.login-modal__google-text span {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  line-height: 14.06px;
  color: #1e2330;
}

.login-modal__google-text span:first-child {
  font-weight: 600;
}

.login-modal__signup {
  margin-top: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.login-modal__signup span {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #ffffff;
}

.login-modal__signup a {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #00e5ff;
  text-decoration: none;
  transition: opacity 0.3s;
}

.login-modal__signup a:hover {
  opacity: 0.8;
}

@media (max-width: 768px) {
  .login-modal {
    padding: 32px;
  }
}
</style>
