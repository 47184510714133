<template>
  <section class="section hero-section">
    <video class="hero-video" autoplay muted loop>
      <source src="../../src/assets/video/hero-video.mp4" type="video/mp4" />
      <!-- Your browser does not support the video tag. -->
    </video>

    <div class="hero-container wrapper">
      <img
        src="./../assets/hero.png"
        class="hero-main-image"
        alt="Hero illustration"
      />

      <div class="hero-tagline">
        {{ $t("for purchasing your own private island in Minecraft!") }}
      </div>

      <div class="hero-heading">
        <div class="hero-heading-primary">{{ $t("Exclusive") }}</div>
        <div class="hero-heading-secondary">{{ $t("destination") }}</div>
      </div>

      <div class="hero-description">
        {{
          $t(
            "Our website is designed to offer a unique, luxurious and safe experience for all our customers."
          )
        }}
      </div>

      <button
        class="hero-button button secondary"
        @click="$emit('scrollToPrice')"
      >
        {{ $t("Buy a Private Island") }}
      </button>

      <ScrollButton class="hero-scroll" @click="$emit('scrollNextSection')" />

      <div class="hero-footer">
        <!-- <div class="hero-payment">
          <img
            v-for="method in paymentMethods"
            :key="method.name"
            :src="method.icon"
            :alt="method.name"
            class="hero-payment-icon"
          />
        </div> -->
        <!-- <SoundToggle class="hero-sound" /> -->
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import ScrollButton from "@/components/ScrollButton.vue";
import SoundToggle from "@/components/SoundToggle.vue";

export default defineComponent({
  name: "HeroSection",

  components: {
    ScrollButton,
    SoundToggle,
  },

  setup() {
    const paymentMethods = [
      { name: "Visa", icon: require("@/assets/visa.svg") },
      { name: "Mastercard", icon: require("@/assets/mastercard.svg") },
      { name: "3D Secure", icon: require("@/assets/3ds.png") },
    ];

    return {
      paymentMethods,
    };
  },
});
</script>

<style lang="scss" scoped>
.hero-section {
  min-height: 1133px;
}

.hero-video {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  pointer-events: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-repeat: no-repeat;
  max-height: 1133px;
}

.hero-container {
  flex-direction: column;
  align-items: center;
}
.hero-container {
  //   position: relative;
  //   max-width: 1200px;
  //   margin: 0 auto;
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
}

.hero-main-image {
  position: absolute;
  top: 108px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 695px;
  height: auto;
  z-index: 1;
  pointer-events: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.hero-tagline {
  position: absolute;
  top: 78px;
  right: 0;
  max-width: 385px;
  font-size: 32px;
  line-height: 40px;
  color: #c65e7f;
  text-align: right;
  font-weight: 200;
  z-index: 2;
}

.hero-heading {
  width: 100%;
  color: #fff;
}

.hero-heading-primary {
  font-size: 160px;
  line-height: 200px;
  font-weight: 700;
  text-align: left;
}

.hero-heading-secondary {
  position: absolute;
  top: 126px;
  right: 0;
  font-size: 160px;
  line-height: 200px;
  font-weight: 700;
  text-align: right;
  z-index: 2;
}

.hero-description {
  margin-top: 84px;
  max-width: 290px;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  font-weight: 400;
  align-self: flex-start;
  z-index: 2;
}

.hero-button {
  margin-top: 97px;
  max-width: 289px;
  width: 100%;
  z-index: 2;
}

.hero-scroll {
  margin-top: 65px;
  z-index: 2;
}

.hero-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 19px;
  z-index: 2;
}

.hero-payment {
  display: flex;
  align-items: center;
  gap: 17px;
}

.hero-payment-icon {
  height: 24px;
  width: auto;
}

/* Tablet styles */
@media (max-width: 1024px) {
  .hero-heading-primary,
  .hero-heading-secondary {
    font-size: 120px;
    line-height: 150px;
  }

  .hero-heading-secondary {
    top: 276px;
  }

  .hero-tagline {
    font-size: 28px;
    line-height: 36px;
    max-width: 320px;
  }

  .hero-main-image {
    max-width: 560px;
  }
}

/* Mobile styles */
@media (max-width: 768px) {
  .hero {
    padding: 20px;
    min-height: 800px;
  }

  .hero-heading-primary,
  .hero-heading-secondary {
    font-size: 64px;
    line-height: 80px;
    text-align: center;
  }

  .hero-heading-secondary {
    position: relative;
    top: 0;
    margin-top: 20px;
  }

  .hero-tagline {
    position: relative;
    top: 0;
    font-size: 24px;
    line-height: 32px;
    max-width: 100%;
    text-align: center;
    margin-bottom: 40px;
  }

  .hero-main-image {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    max-width: 100%;
    margin: 40px 0;
  }

  .hero-description {
    margin-top: 40px;
    text-align: center;
    max-width: 100%;
    align-self: center;
  }

  .hero-button {
    margin-top: 40px;
  }

  .hero-scroll {
    margin-top: 40px;
  }

  .hero-footer {
    flex-direction: column;
    gap: 20px;
  }

  .hero-payment {
    justify-content: center;
    width: 100%;
  }

  .hero-sound {
    margin-top: 20px;
  }
}

/* Small mobile styles */
@media (max-width: 480px) {
  .hero-heading-primary,
  .hero-heading-secondary {
    font-size: 48px;
    line-height: 60px;
  }

  .hero-tagline {
    font-size: 20px;
    line-height: 28px;
  }

  .hero-payment-icon {
    height: 20px;
  }
}
</style>
