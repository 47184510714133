<template>
  <div class="modal offer-modal">
    <div class="overlay" @click="$parent.closeReadyModal"></div>
    <div class="wrapper">
      <img
        class="close"
        @click="$parent.closeReadyModal"
        src="./../assets/close.svg"
        alt="Close"
      />
      <div class="container">
        <div class="form-wrapper" v-if="!$parent.successMessage">
          <div class="title">{{ $t("Are you sure?") }}</div>
          <div class="price title">
            {{ $t("TOTAL") }}: {{ $parent.islandToBuy.price }}
            {{ $parent.currency }}
          </div>
          <button class="button" @click="$parent.buyIsland">
            {{ $t("Buy") }}
          </button>
          <transition name="fade">
            <div class="desc red" v-if="$parent.error">{{ $parent.error }}</div>
          </transition>
        </div>
        <transition name="fade">
          <div v-if="$parent.successMessage">
            <div class="title green">{{ $parent.successMessage }}</div>
            <button class="button" @click="$parent.closeReadyModal">
              {{ $t("Ok") }}
            </button>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReadyModal",
  props: [],
  components: {},
  data: function () {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.offer-modal .form-wrapper {
  text-align: center;
}
</style>
