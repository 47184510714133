var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"data-table"},[_c('table',{staticClass:"data-table__table"},[_c('thead',[_c('tr',_vm._l((_vm.headers),function(header){return _c('th',{key:header.key,class:[
            'data-table__header',
            { 'data-table__header--left': header.align === 'left' },
            { 'data-table__header--center': header.align === 'center' },
            { 'data-table__header--right': header.align === 'right' },
          ]},[_vm._v(" "+_vm._s(header.label)+" ")])}),0)]),_c('tbody',_vm._l((_vm.paginatedData),function(row,index){return _c('tr',{key:index,staticClass:"data-table__row"},_vm._l((_vm.headers),function(header){return _c('td',{key:header.key,class:[
            'data-table__cell',
            { 'data-table__cell--left': header.align === 'left' },
            { 'data-table__cell--center': header.align === 'center' },
            { 'data-table__cell--right': header.align === 'right' },
          ]},[_vm._v(" "+_vm._s(_vm.formatCell(row[header.key], header))+" ")])}),0)}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }