<!-- ProductCard.vue -->
<template>
  <div class="product-card">
    <div class="product-content">
      <h3 class="product-title">{{ item.title }}</h3>
      <p class="product-host" v-if="item.host">
        {{ item.host }} ({{ item.port }})
      </p>
      <p class="desc product-dimensions">{{ item.dimensions }}</p>
    </div>

    <div class="product-image-wrapper">
      <img
        :src="imgDomain + item.image_url"
        :alt="item.title"
        class="product-image"
      />
    </div>

    <div class="product-content">
      <p class="product-price">
        {{ item.price }}<span class="currency">{{ currency }}</span>
      </p>

      <button @click="handleClick" class="button secondary">
        {{ $t("Buy a Private Island") }}
      </button>
    </div>
  </div>
</template>

<script>
import { MODALS } from "../store/modules/modals";

export default {
  name: "ProductCard",
  props: ["item", "currency", "isAuth"],
  data: function () {
    return {
      imgDomain: "",
    };
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
  },
  methods: {
    handleClick() {
      if (this.isAuth) {
        this.$router.push({ path: "/profile/buy-island" });
      } else {
        this.$store.dispatch("modals/openModal", {
          modalName: MODALS.SIGNUP,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.product-card {
  width: 393px;
  background: #433144;
  border-radius: 18px;
  position: relative;
}

.product-content {
  padding: 24px 0 23px;
}

.product-title {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 9px;
}

.product-dimensions {
  color: #c65e7f;
  text-align: center;
}

.product-image-wrapper {
  width: 100%;
  height: 223px;
}

.product-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product-price {
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 24px;
}

.button.secondary {
  bottom: 0;
  position: absolute;
  left: 50%;
  max-width: 289px;
  transform: translateX(-50%) translateY(50%);
}

@media (max-width: 768px) {
  .product-card {
    width: 100%;
  }
}
</style>
