<!-- components/ModalManager.vue -->
<template>
  <div class="modal-manager">
    <transition name="fade">
      <login-modal
        @clearError="handleClearError"
        :error="error"
        v-if="isModalOpen(MODALS.LOGIN)"
        @close="handleCloseModal"
        @open-signup="openModal({ modalName: MODALS.SIGNUP })"
        @open-forgot-password="openModal({ modalName: MODALS.FORGOT_PASSWORD })"
        @submit="handleLoginSubmit"
      />
    </transition>

    <transition name="fade">
      <signup-modal
        @clearError="handleClearError"
        :error="error"
        v-if="isModalOpen(MODALS.SIGNUP)"
        @close="handleCloseModal"
        @open-login="openModal({ modalName: MODALS.LOGIN })"
        @submit="handleSignupSubmit"
      />
    </transition>

    <transition name="fade">
      <forgot-password-modal
        @clearError="handleClearError"
        :error="error"
        :successMessage="successMessage"
        v-if="isModalOpen(MODALS.FORGOT_PASSWORD)"
        @close="handleCloseModal"
        @open-login="openModal({ modalName: MODALS.LOGIN })"
        @submit="handleForgotPasswordSubmit"
      />
    </transition>

    <transition name="fade">
      <amount-modal
        @clearError="handleClearError"
        :error="error"
        v-if="isModalOpen(MODALS.AMOUNT)"
        @close="handleCloseModal"
        @proceed="handleAmountProceed"
      />
    </transition>

    <transition name="fade">
      <contact-info-modal
        @clearError="handleClearError"
        :error="error"
        v-if="isModalOpen(MODALS.CONTACT_INFO)"
        @close="handleCloseModal"
        v-bind="modalProps"
      />
    </transition>

    <transition name="fade">
      <add-island-modal
        @clearError="handleClearError"
        :error="error"
        v-if="isModalOpen(MODALS.ADD_ISLAND)"
        @close="handleCloseModal"
      />
    </transition>

    <!-- Error Toast -->
    <!-- <transition name="slide-up">
      <div v-if="error" class="modal-error">
        {{ error }}
        <button class="modal-error__close" @click="handleClearError">×</button>
      </div>
      <div v-else-if="successMessage" class="modal-success">
        {{ successMessage }}
        <button class="modal-error__close" @click="handleClearError">×</button>
      </div>
    </transition> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { MODALS } from "@/store/modules/modals";
import LoginModal from "./modals/LoginModal.vue";
import SignupModal from "./modals/SignupModal.vue";
import ForgotPasswordModal from "./modals/ForgotPasswordModal.vue";
import AmountModal from "./modals/AmountModal.vue";
import ContactInfoModal from "./modals/ContactInfoModal.vue";
import AddIslandModal from "./modals/AddIslandModal.vue";

export default {
  name: "ModalManager",
  props: {
    error: {
      type: String,
      default: "",
    },
    successMessage: {
      type: String,
      default: "",
    },
  },
  emits: [
    "clearError",
    "submitLogin",
    "submitRegistration",
    "submitForgotPassword",
  ],
  components: {
    LoginModal,
    SignupModal,
    ForgotPasswordModal,
    AmountModal,
    ContactInfoModal,
    AddIslandModal,
  },

  data() {
    return {
      MODALS,
    };
  },

  computed: {
    ...mapGetters("modals", ["isModalOpen", "modalProps"]),
  },

  methods: {
    ...mapActions("modals", ["openModal", "closeModal"]),

    handleCloseModal() {
      this.closeModal();
      this.handleClearError();
    },

    handleClearError() {
      this.$emit("clearError");
    },
    handleLoginSubmit($data) {
      this.$emit("submitLogin", $data);
    },

    handleSignupSubmit($data) {
      this.$emit("submitRegistration", $data);
    },

    handleForgotPasswordSubmit($data) {
      this.$emit("submitForgotPassword", $data);
    },

    handleAmountProceed(amount) {
      this.openModal({
        modalName: MODALS.CONTACT_INFO,
        props: { amount },
      });
    },
  },

  watch: {
    $route() {
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-error {
  position: fixed;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  background: #c65e7f;
  color: white;
  padding: 16px 48px 16px 24px;
  border-radius: 6px;
  z-index: 2000;

  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 90%;
  word-wrap: break-word;
  white-space: pre-wrap;
  line-height: 1.5;

  &__close {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
    padding: 4px;

    &:hover {
      opacity: 0.8;
    }
  }
}

.modal-success {
  @extend .modal-error;
  background: #4bb543;
}

@media (max-width: 768px) {
  .modal-error {
    width: 100%;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.3s;
}

.slide-up-enter,
.slide-up-leave-to {
  transform: translate(-50%, 100%);
  opacity: 0;
}
</style>
