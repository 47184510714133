<template>
  <section class="why-section">
    <div class="why-section-content">
      <h2 class="why-section-title">Why Choose Us?</h2>
      <div class="why-section-info-block">
        <p class="why-section-description">
          We are the leading provider of private islands in Minecraft, and we
          take pride in offering our customers the highest level of service and
          support. Our team of experts is always available to answer your
          questions and provide you with the information you need to make the
          right decision.<br /><br />If you are looking for a unique and
          luxurious experience in Minecraft, look no further than Private Island
          Sales. Explore our website today to discover our wide range of private
          islands and start building your own paradise!
        </p>
      </div>
      <button @click="handleClick" class="button secondary">
        {{ $t("Buy a Private Island") }}
      </button>
    </div>
  </section>
</template>

<script>
import { MODALS } from "../store/modules/modals";

export default {
  name: "WhySection",
  props: {
    isAuth: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {},
  methods: {
    handleClick() {
      if (this.isAuth) {
        this.$router.push({ path: "/profile/buy-island" });
      } else {
        this.$store.dispatch("modals/openModal", {
          modalName: MODALS.SIGNUP,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// Using descriptive class names without nesting for better DevTools visibility
.why-section {
  position: relative;
  width: 100%;
  max-width: 1596px;
  margin: 0 auto;
  padding: 207px 162px 99px;
  background-image: url("../../src/assets/why-bg.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: 1600px) {
    padding: 116px 80px 99px;
  }

  @media (max-width: 1024px) {
    padding: 80px 40px;
  }

  @media (max-width: 768px) {
    padding: 60px 20px;
  }
}

.why-section-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.why-section-title {
  font-family: "Outfit", sans-serif;
  font-size: 64px;
  font-weight: 700;
  line-height: 1.26;
  color: #fff;
  text-align: center;
  margin-bottom: 53px;

  @media (max-width: 768px) {
    font-size: 40px;
    line-height: 1.2;
    margin-bottom: 40px;
  }
}

.why-section-info-block {
  width: 100%;
  max-width: 1020px;
  padding: 64px 105px;
  background: rgba(0, 0, 0, 0.64);
  backdrop-filter: blur(4px);
  border-radius: 18px;
  margin-bottom: 39px;

  @media (max-width: 1024px) {
    padding: 40px 60px;
  }

  @media (max-width: 768px) {
    padding: 30px 20px;
    border-radius: 12px;
  }
}

.why-section-description {
  font-family: "Outfit", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.26;
  color: #fff;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 1.4;
  }
}

.button.secondary {
  max-width: 289px;
}
</style>
