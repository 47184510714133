<!-- ContactInfoModal.vue -->
<template>
  <BaseModal @close="$emit('close')" size="large">
    <div class="contact-info-modal">
      <h1 class="contact-info-modal__title">Enter contact information</h1>

      <form @submit.prevent="handleSubmit" class="contact-info-modal__form">
        <div class="contact-info-modal__columns">
          <div class="contact-info-modal__column">
            <BaseInput
              v-model="firstName"
              placeholder="First name"
              label="First name: *"
            />

            <BaseInput
              v-model="email"
              type="email"
              placeholder="E-mail"
              label="E-mail: *"
            />

            <BaseInput
              v-model="country"
              placeholder="Country"
              label="Country: *"
            />

            <BaseInput
              v-model="address"
              placeholder="Address"
              label="Address: *"
            />
          </div>

          <div class="contact-info-modal__column">
            <BaseInput
              v-model="lastName"
              placeholder="Last name"
              label="Last name: *"
            />

            <BaseInput
              v-model="phone"
              type="tel"
              placeholder="Phone"
              label="Phone: *"
            />

            <BaseInput v-model="city" placeholder="City" label="City: *" />

            <BaseInput
              v-model="postCode"
              placeholder="Post Code"
              label="Post Code: *"
            />
          </div>
        </div>

        <div class="contact-info-modal__footer">
          <div class="contact-info-modal__terms">
            <label>
              <input type="checkbox" v-model="acceptTerms" />
              <span>I agree with privacy policy and terms and conditions</span>
            </label>
          </div>

          <div class="contact-info-modal__total">TOTAL: {{ amount }} €</div>

          <div class="contact-info-modal__payment">
            <div class="contact-info-modal__payment-methods">
              <img src="@/assets/visa.svg" alt="Visa" />
              <img src="@/assets/mastercard.svg" alt="Mastercard" />
              <img src="@/assets/3ds.png" alt="3D Secure" />
            </div>
            <button class="button secondary">Debit/Credit Card</button>
          </div>
        </div>
      </form>
    </div>
  </BaseModal>
</template>

<script>
export default {
  name: "ContactInfoModal",
  props: {
    amount: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      country: "",
      city: "",
      address: "",
      postCode: "",
      acceptTerms: false,
    };
  },
  methods: {
    handleSubmit() {
      // Handle form submission
    },
  },
};
</script>
