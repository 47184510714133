<template>
  <div class="base-form-field">
    <label v-if="label" class="base-form-label">
      <div class="base-form-label-text">{{ label }}</div>
      <div class="base-form-select-wrapper">
        <select
          :value="value"
          @input="handleSelect($event.target.value)"
          class="base-form-select"
          :class="{ 'base-form-select-error': error }"
          required
          v-bind="$attrs"
        >
          <option value="" disabled selected>
            {{ placeholder || "Select an option" }}
          </option>
          <option
            v-for="option in processedOptions"
            :key="option.id"
            :value="option.id"
          >
            {{ option.title || option.name }}
          </option>
        </select>
        <div class="base-form-select-arrow">
          <svg
            width="12"
            height="8"
            viewBox="0 0 12 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1.5L6 6.5L11 1.5"
              stroke="#A18DA1"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
    </label>
    <div v-if="error" class="base-form-error">{{ error }}</div>
  </div>
</template>

<script>
export default {
  name: "BaseSelect",
  props: {
    value: {
      type: [String, Number],
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      error: "",
      processedOptions: [],
    };
  },
  watch: {
    options: {
      immediate: true,
      handler(newOptions) {
        this.validateAndProcessOptions(newOptions);
      },
    },
  },
  methods: {
    validateAndProcessOptions(options) {
      try {
        if (!Array.isArray(options)) {
          this.error = "Invalid data format";
          this.processedOptions = [];
          return;
        }

        this.processedOptions = options.filter((option) => {
          if (!option || typeof option !== "object") return false;
          if (!("id" in option) || !("title" in option)) return false;
          return true;
        });

        if (this.processedOptions.length === 0 && options.length > 0) {
          this.error = "No valid options available";
        } else {
          this.error = "";
        }
      } catch (e) {
        console.error("Error processing options:", e);
        this.error = "Error loading options";
        this.processedOptions = [];
      }
    },
    handleSelect(value) {
      try {
        const numericValue = parseInt(value, 10);
        if (isNaN(numericValue)) {
          this.error = "Invalid selection";
          return;
        }

        const selectedOption = this.processedOptions.find(
          (opt) => opt.id === numericValue
        );
        if (!selectedOption) {
          this.error = "Invalid selection";
          return;
        }

        this.error = "";
        this.$emit("input", numericValue);
        this.$emit("change", selectedOption);
      } catch (e) {
        console.error("Error handling selection:", e);
        this.error = "Error processing selection";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.base-form-field {
  width: 100%;
}

.base-form-label {
  display: block;
  width: 100%;
}

.base-form-label-text {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: #a18da1;
  padding: 0 14px 8px;
}

.base-form-select-wrapper {
  position: relative;
  width: 100%;
}

.base-form-select {
  width: 100%;
  height: 39px;
  background: #271a28;
  border: 1px solid #6b576c;
  border-radius: 20px;
  font-family: inherit;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #fff;
  outline: 0;
  padding: 7px 40px 7px 14px;
  transition: border-color 0.2s;
  appearance: none;
  cursor: pointer;

  &::placeholder {
    color: #a18da1;
  }

  &:focus {
    border-color: #8b728c;
  }

  &.base-form-select-error {
    border-color: #ff4444;
  }

  &:required:invalid {
    color: #a18da1;
  }

  option {
    background: #271a28;
    color: #fff;
    padding: 8px;

    &[disabled] {
      color: #a18da1;
    }

    &:not([disabled]) {
      color: #fff;
    }
  }

  &:not(:required),
  &:valid {
    color: #fff;
  }
}

.base-form-select-arrow {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.base-form-error {
  color: #ff4444;
  font-size: 12px;
  margin-top: 4px;
  padding: 0 14px;
}
</style>
