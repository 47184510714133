<template>
  <header class="header">
    <div class="wrapper">
      <!-- Logo Section -->
      <div class="left">
        <router-link to="/home" class="logo">
          <div class="title">cubes<span>couture</span></div>
        </router-link>
      </div>

      <!-- Navigation Section -->
      <ul class="nav">
        <li v-for="item in $parent.nav" :key="item.title" class="nav-item">
          <router-link class="nav-link" :to="item.path">
            {{ $t(item.title) }}
          </router-link>
        </li>
      </ul>

      <!-- Right Section -->
      <div class="right">
        <!-- If Authenticated -->
        <router-link
          v-if="$parent.isAuth"
          to="/profile/personal-information"
          class="user-card"
        >
          <div class="user-nickname">{{ $parent.name }}</div>
          <div class="user-icon">
            <img src="./../assets/user.svg" alt="User Icon" />
          </div>
        </router-link>

        <!-- If Not Authenticated -->
        <div v-else class="auth-actions">
          <button class="button transparent" @click="openSignIn">
            {{ $t("Login") }}
          </button>
          <button class="button" @click="openSignUp">
            {{ $t("Sign Up") }}
          </button>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapActions } from "vuex";
import { MODALS } from "../store/modules/modals";

export default {
  name: "Header",
  props: [],
  data: function () {
    return {};
  },
  methods: {
    ...mapActions("modals", ["openLoginModal", "openSignupModal"]),

    openSignIn() {
      this.$store.dispatch("modals/openModal", {
        modalName: MODALS.LOGIN,
      });
      // this.$router.push({ path: "/login" });
    },
    openSignUp() {
      this.$store.dispatch("modals/openModal", {
        modalName: MODALS.SIGNUP,
      });
      // this.$router.push({ path: "/registration" });
    },
  },
};
</script>

<style lang="scss" scoped>
/* General Header Styles */
.header {
  padding: 40px 16px 0;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  background: transparent;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

/* Logo Section */
.left {
  display: flex;
  flex: 1;
  align-items: center;
}

.logo .title {
  color: #fff;
  font-size: 32px;
  line-height: 32px;
  font-weight: 700;
  letter-spacing: -0.019em;
}

.logo .title span {
  color: #c65e7f;
}

/* Navigation Styles */
.nav {
  display: flex;
  gap: 25px;
  align-items: center;
  justify-content: center;
}

.nav-item {
  text-align: center;
  text-transform: uppercase;
}

.nav-link {
  display: block;
  color: #fff;
  line-height: 32px;
  letter-spacing: -0.019em;
  text-decoration: none;
  transition: color 0.3s ease;
}

/* Right Section */
.right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
}

.auth-actions {
  display: flex;
  gap: 13px;
}

.button {
}

.button:hover {
}

/* User Card */
.user-card {
  display: flex;
  align-items: center;
  gap: 13px;
  background: transparent;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  height: 46px;
}

.user-nickname {
  font-size: 16px;
  line-height: 32px;
  color: #fff;
  white-space: nowrap;
  transition: color 0.3s ease;
}

.user-card:hover .user-nickname {
  color: #00e5ff;
}

.user-icon {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00e5ff;
  border-radius: 50%;
  transition: background 0.3s ease-in-out, border 0.3s ease-in-out;
}

.user-icon img {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.user-card:hover .user-icon {
  background: linear-gradient(0deg, #00e5ff 0%, #00c8ff 100%);
  border: 2px solid #00e5ff;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .wrapper {
    gap: 16px;
    flex-direction: column;
  }

  .nav {
  }

  .right {
    justify-content: center;
  }
}
</style>
