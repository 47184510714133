<!-- AmountModal.vue -->
<template>
  <BaseModal @close="$emit('close')" size="small">
    <div class="amount-modal">
      <h1 class="amount-modal__title">Enter amount</h1>

      <div class="amount-modal__field">
        <label>Amount:</label>
        <div class="amount-modal__input-wrapper">
          <input
            type="number"
            v-model="amount"
            placeholder="300"
            class="amount-modal__input"
          />
          <span class="amount-modal__currency">€</span>
        </div>
      </div>

      <button class="button" @click="handleNext">NEXT</button>
    </div>
  </BaseModal>
</template>

<script>
export default {
  name: "AmountModal",
  data() {
    return {
      amount: "",
    };
  },
  methods: {
    handleNext() {
      this.$emit("proceed", this.amount);
    },
  },
};
</script>
<style lang="scss" scoped>

/* Amount Modal Styles */
.amount-modal {
  &__title {
    font-family: 'Outfit', sans-serif;
    font-size: 32px;
    font-weight: 800;
    line-height: 40.32px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 51px;
  }

  &__field {
    margin-bottom: 32px;

    label {
      display: block;
      font-family: 'Outfit', sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 20.16px;
      color: #A18DA1;
      margin-bottom: 8px;
    }
  }

  &__input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
  }

  &__input {
    width: 100%;
    height: 48px;
    background: rgba(255, 255, 255, 0.1);
    border: none;
    border-radius: 6px;
    padding: 0 48px;
    font-family: 'Outfit', sans-serif;
    font-size: 18px;
    color: #FFFFFF;
    text-align: center;

    &::placeholder {
      color: rgba(255, 255, 255, 0.5);
    }
  }

  &__currency {
    position: absolute;
    right: 16px;
    color: #FFFFFF;
    font-family: 'Outfit', sans-serif;
    font-size: 18px;
  }
}

</style>