<!-- BaseModal.vue -->
<template>
  <div class="reusable-modal">
    <div class="reusable-modal__overlay" @click="$emit('close')"></div>
    <div class="reusable-modal__wrapper" :class="size">
      <div class="reusable-modal__close" @click="$emit('close')">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_83_451)">
            <path
              d="M18 6L6 18"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6 6L18 18"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_83_451">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div class="reusable-modal__container">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseModal",
  props: {
    size: {
      type: String,
      default: "medium", // small, medium, large
      validator: (value) => ["small", "medium", "large"].includes(value),
    },
  },
};
</script>

<style lang="scss" scoped>
.reusable-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.reusable-modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #1E121FE5;
  backdrop-filter: blur(16px)
}

.reusable-modal__wrapper {
  position: relative;
  background: #433144;
  border-radius: 12px;
  margin: 20px;
  max-height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 40px);
  animation: modalAppear 0.3s ease-out;
}

.reusable-modal__wrapper.small {
  width: 594px;
}

.reusable-modal__wrapper.medium {
  width: 594px;
}

.reusable-modal__wrapper.large {
  width: 1020px;
}

.reusable-modal__close {
  position: absolute;
  top: 24px;
  right: 24px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  z-index: 2;
}

.reusable-modal__close svg {
  color: #a18da1;
  transition: color 0.3s;
}

.reusable-modal__close svg:hover {
  color: #fff;
}

.reusable-modal__container {
  // padding: 46px;
  overflow-y: auto;
  max-height: calc(100vh - 40px);
  scrollbar-width: thin;
  scrollbar-color: #a18da1 transparent;
}

.reusable-modal__container::-webkit-scrollbar {
  width: 6px;
}

.reusable-modal__container::-webkit-scrollbar-track {
  background: transparent;
}

.reusable-modal__container::-webkit-scrollbar-thumb {
  background-color: #a18da1;
  border-radius: 3px;
}

@media (max-width: 768px) {
  .reusable-modal__wrapper {
    margin: 16px;
    max-width: calc(100% - 32px);
  }

  .reusable-modal__container {
    // padding: 32px 24px;
  }
}
</style>
