<template>
  <div class="base-form-field">
    <label v-if="label" class="base-form-label">
      <div class="base-form-label-text">{{ label }}</div>
      <div class="base-form-input-wrapper">
        <slot name="icon-left">
          <img
            v-if="leftIcon"
            :src="leftIcon"
            class="base-form-icon base-form-icon-left"
            :alt="label || 'Textarea icon'"
          />
        </slot>
        <textarea
          :value="value"
          @input="updateValue($event.target.value)"
          class="base-form-textarea"
          :style="heightStyle"
          :class="{
            'base-form-textarea-with-left-icon':
              $slots['icon-left'] || leftIcon,
            'base-form-textarea-with-right-icon':
              $slots['icon-right'] || rightIcon,
          }"
          v-bind="$attrs"
        ></textarea>
        <slot name="icon-right">
          <img
            v-if="rightIcon"
            :src="rightIcon"
            class="base-form-icon base-form-icon-right"
            :alt="label || 'Textarea icon'"
          />
        </slot>
      </div>
    </label>
    <div v-else class="base-form-input-wrapper">
      <slot name="icon-left">
        <img
          v-if="leftIcon"
          :src="leftIcon"
          class="base-form-icon base-form-icon-left"
          :alt="'Textarea icon'"
        />
      </slot>
      <textarea
        :value="value"
        @input="updateValue($event.target.value)"
        class="base-form-textarea"
        :class="{
          'base-form-textarea-with-left-icon': $slots['icon-left'] || leftIcon,
          'base-form-textarea-with-right-icon':
            $slots['icon-right'] || rightIcon,
        }"
        :style="heightStyle"
        v-bind="$attrs"
      ></textarea>
      <slot name="icon-right">
        <img
          v-if="rightIcon"
          :src="rightIcon"
          class="base-form-icon base-form-icon-right"
          :alt="'Textarea icon'"
        />
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseTextarea",
  props: {
    value: {
      type: [String, Number],
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    leftIcon: {
      type: String,
      default: "",
    },
    rightIcon: {
      type: String,
      default: "",
    },
    height: {
      type: [String, Number],
      default: "200",
    },
  },
  mounted() {},
  methods: {
    updateValue(value) {
      this.$emit("input", value);
    },
  },
  computed: {
    heightStyle() {
      if (!this.height) return {};

      const height =
        typeof this.height === "number" ? `${this.height}px` : this.height;

      return { height };
    },
  },
  emits: ["update:modelValue"],
};
</script>

<style lang="scss" scoped>
.base-form-field {
  width: 100%;
}

.base-form-label {
  display: block;
  width: 100%;
}

.base-form-label-text {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: #a18da1;
  padding: 0 14px 8px;
}

.base-form-input-wrapper {
  position: relative;
  width: 100%;
}

.base-form-icon {
  position: absolute;
  top: 14px;
  transform: none;
  width: 24px;
  height: 24px;
  pointer-events: none;
}

.base-form-icon-left {
  left: 16px;
}

.base-form-icon-right {
  right: 16px;
}

.base-form-textarea {
  width: 100%;
  min-height: 120px;
  background: #271a28;
  border: 1px solid #6b576c;
  border-radius: 20px;
  font-family: inherit;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #fff;
  outline: 0;
  padding: 14px;
  resize: none;
  transition: border-color 0.2s;

  &::placeholder {
    color: #a18da1;
  }

  &:focus {
    border-color: #8b728c;
  }
}

.base-form-textarea-with-left-icon {
  padding-left: 54px;
}

.base-form-textarea-with-right-icon {
  padding-right: 54px;
}
</style>
