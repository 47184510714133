<template>
  <BaseModal @close="handleClose" size="medium">
    <div class="signup-modal">
      <h1 class="signup-modal__title">Sign up</h1>
      <p class="signup-modal__subtitle">to continue</p>

      <form @submit.prevent="handleSubmit" class="signup-modal__form">
        <BaseInput
          class="signup-modal__input"
          v-model="formData.name"
          type="text"
          placeholder="First name"
          :error="validationErrors.name"
          :left-icon="require('@/assets/user.svg')"
          @input="clearError('name')"
        />

        <BaseInput
          class="signup-modal__input"
          v-model="formData.surname"
          type="text"
          placeholder="Last name"
          :error="validationErrors.surname"
          :left-icon="require('@/assets/user.svg')"
          @input="clearError('surname')"
        />

        <BaseInput
          class="signup-modal__input"
          v-model="formData.email"
          type="email"
          placeholder="Email address"
          :error="validationErrors.email"
          :left-icon="require('@/assets/mail.svg')"
          @input="clearError('email')"
        />

        <BaseInput
          class="signup-modal__input"
          v-model="formData.phone"
          type="tel"
          placeholder="Phone number"
          :error="validationErrors.phone"
          :left-icon="require('@/assets/user.svg')"
          @input="clearError('phone')"
        />

        <BaseInput
          class="signup-modal__input"
          v-model="formData.password"
          :type="showPassword ? 'text' : 'password'"
          placeholder="Password"
          :error="validationErrors.password"
          :left-icon="require('@/assets/key.svg')"
          :right-icon="require('@/assets/eye.svg')"
          :rightIconClickable="true"
          @right-icon-click="togglePassword"
          @input="clearError('password')"
        />

        <BaseInput
          class="signup-modal__input"
          v-model="formData.passConfirm"
          :type="showPassConfirm ? 'text' : 'password'"
          placeholder="Confirm password"
          :error="validationErrors.passConfirm"
          :left-icon="require('@/assets/key.svg')"
          :right-icon="require('@/assets/eye.svg')"
          :rightIconClickable="true"
          @right-icon-click="togglepassConfirm"
          @input="clearError('passConfirm')"
        />

        <div class="signup-modal__error" v-if="error">
          {{ error }}
        </div>

        <div class="signup-modal__terms-container">
          <div class="signup-modal__terms">
            <BaseCheckbox
              v-model="formData.acceptTerms"
              id="accept-terms"
              @change="clearError('acceptTerms')"
            >
              <span class="">{{ $t("I accept") }} </span>

              <router-link :to="termsLink" class="desc" v-if="termsLink">{{
                $t("Terms")
              }}</router-link>

              <span class=""> {{ $t("and") }} </span>

              <router-link :to="privacyLink" class="desc" v-if="privacyLink">{{
                $t("Privacy")
              }}</router-link>
            </BaseCheckbox>
          </div>
          <button
            class="button signup-modal__button"
            :disabled="!requiredFieldsAreFilled"
          >
            {{ "Sign up" }}
          </button>
        </div>

        <div class="signup-modal__divider">OR</div>

        <button
          type="button"
          class="signup-modal__google"
          @click="redirectGoogleLogin"
        >
          <img
            src="@/assets/google.svg"
            alt="Google"
            class="signup-modal__google-icon"
          />
          Continue with Google
        </button>

        <div class="signup-modal__login">
          <span>Have an account?</span>
          <a href="#" @click.prevent="openLogin">Log in</a>
        </div>
      </form>
    </div>
  </BaseModal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "SignupModal",
  props: {
    error: {
      type: String,
      default: "",
    },
  },
  components: {},

  data() {
    return {
      formData: {
        name: "",
        surname: "",
        email: "",
        phone: "",
        password: "",
        passConfirm: "",
        acceptTerms: false,
      },
      validationErrors: {
        name: "",
        surname: "",
        email: "",
        phone: "",
        password: "",
        passConfirm: "",
        acceptTerms: "",
      },
      showPassword: false,
      showPassConfirm: false,
      googleUser: null,
    };
  },

  computed: {
    ...mapGetters("staticPages", ["getPrivacyPage", "getTermsPage"]),

    privacyLink() {
      const page = this.getPrivacyPage;
      return page ? `/pages/${page.slug}` : null;
    },

    termsLink() {
      const page = this.getTermsPage;
      return page ? `/pages/${page.slug}` : null;
    },

    requiredFieldsAreFilled() {
      let fieldsAreFilled;
      if (
        this.formData.name &&
        this.formData.surname &&
        this.formData.phone &&
        this.formData.email &&
        this.formData.password &&
        this.formData.passConfirm &&
        this.formData.acceptTerms
      ) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    },
  },

  methods: {
    // ...mapActions("auth", ["register"]),
    ...mapActions("modals", ["closeModal"]),

    clearError(field) {
      if (field) {
        this.validationErrors[field] = "";
      }
      this.$emit("clearError");
      // this.error = "";
    },

    async handleSubmit() {
      const userData = {
        ...this.formData,
      };

      this.$emit("submit", userData);

      //   const result = await this.register(userData);

      //   if (result.success) {
      //     this.handleClose();
      //     this.$router.push("/profile/my-islands");
      //   } else {
      //     this.error = result.error;
      //   }
    },

    handleClose() {
      this.formData = {
        email: "",
        password: "",
        passConfirm: "",
        acceptTerms: false,
      };
      this.validationErrors = {
        email: "",
        password: "",
        passConfirm: "",
        acceptTerms: "",
      };
      this.$emit("clearError");
      this.closeModal();
    },

    togglePassword() {
      this.showPassword = !this.showPassword;
    },

    togglepassConfirm() {
      this.showPassConfirm = !this.showPassConfirm;
    },

    openLogin() {
      this.$emit("open-login");
    },

    redirectGoogleLogin() {
      window.location.href = `${process.env.VUE_APP_API}auth/google/redirect`;
    },
  },
};
</script>

<style lang="scss" scoped>
.signup-modal {
  padding: 46px 119px 32px;
}
.signup-modal__error {
  color: #e53e3e;
  padding: 0.75rem;
  border-radius: 0.375rem;
  margin-bottom: 1rem;
  font-size: 0.875rem;
}

.signup-modal__title {
  text-transform: uppercase;
  font-family: "Outfit", sans-serif;
  font-size: 32px;
  font-weight: 800;
  line-height: 40.32px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 8px;
}

.signup-modal__subtitle {
  font-size: 20px;
  font-weight: 200;
  line-height: 25.2px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 46px;
}

.signup-modal__form {
  display: flex;
  flex-direction: column;
  // gap: 24px;
}

.signup-modal__input + .signup-modal__input {
  margin-top: 24px;
}

.signup-modal__terms-container {
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.signup-modal__terms {
  max-width: 155px;
}

.signup-modal__button {
  max-width: 126px;
}

.signup-modal__divider {
  font-size: 14px;
  font-weight: 200;
  line-height: 40px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  margin: 20px 0 25px;
  position: relative;
}

.signup-modal__terms label {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.signup-modal__terms label input[type="checkbox"] {
  width: 20px;
  height: 20px;
  border: 2px solid #a18da1;
  border-radius: 4px;
  appearance: none;
  cursor: pointer;
}

.signup-modal__terms label input[type="checkbox"]:checked {
  background: #c65e7f;
  border-color: #c65e7f;
}

.signup-modal__terms label span {
  font-size: 14px;
  // color: #ffffff;
}

.signup-modal__google {
  width: 100%;
  max-width: 200px;
  height: 40px;
  background: #ffffff;
  border-radius: 6px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 6px 14px;
  cursor: pointer;
  transition: background 0.3s;
  color: #1e2330;
}

.signup-modal__google:hover {
  background: #f5f5f5;
}

.signup-modal__google-icon {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}

.signup-modal__google-text {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.signup-modal__google-text span {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  line-height: 14.06px;
  color: #1e2330;
}

.signup-modal__google-text span:first-child {
  font-weight: 600;
}

.signup-modal__login {
  margin-top: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.signup-modal__login span {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #ffffff;
}

.signup-modal__login a {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #00e5ff;
  text-decoration: none;
  transition: opacity 0.3s;
}

@media (max-width: 768px) {
  .signup-modal {
    padding: 32px;
  }
}
</style>
