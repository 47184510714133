<template>
  <div class="modal offer-modal top-modal">
    <div class="overlay" @click="$parent.closeTopUpModal"></div>
    <div
      class="wrapper"
      :class="{ 'modal-personal-info-wrapper': !amountStep }"
    >
      <img
        class="modal-close-icon close"
        @click="$parent.closeTopUpModal"
        src="./../assets/close.svg"
        alt="Close"
      />
      <div class="modal-personal-info-container">
        <!-- Amount step remains unchanged -->
        <div class="modal-amount-wrapper" v-if="amountStep">
          <div class="title modal-amount-title">{{ $t("Enter amount") }}</div>
          <div class="modal-amount-input-group">
            <BaseInput
              v-model="amount"
              type="number"
              label="Amount:"
              class="modal-amount-input"
            >
              <template #icon-right>
                <span class="modal-amount-currency">€</span>
              </template>
            </BaseInput>
            <button class="modal-amount-button button" @click="nextStep">
              {{ $t("Next") }}
            </button>
          </div>
        </div>

        <!-- Personal Information Step -->
        <div class="modal-personal-form-wrapper" v-if="!amountStep">
          <h2 class="modal-personal-title">
            {{ $t("Enter contact information") }}
          </h2>

          <div class="modal-personal-form-grid">
            <!-- Left Column -->
            <div class="modal-personal-form-column">
              <BaseInput
                v-model="name"
                type="text"
                :label="$t('First name') + ': *'"
                class="modal-personal-input"
              />

              <BaseInput
                v-model="email"
                type="email"
                :label="$t('E-mail') + ': *'"
                class="modal-personal-input"
              />

              <BaseSelect
                v-model="country"
                :options="countryOptions"
                :label="$t('Country') + ': *'"
                :placeholder="$t('Select country')"
                class="modal-personal-input"
              />

              <BaseInput
                v-model="address"
                type="text"
                :label="$t('Address') + ': *'"
                class="modal-personal-input"
              />
            </div>

            <!-- Right Column -->
            <div class="modal-personal-form-column">
              <BaseInput
                v-model="surname"
                type="text"
                :label="$t('Last name') + ': *'"
                class="modal-personal-input"
              />

              <BaseInput
                v-model="phone"
                type="tel"
                :label="$t('Phone') + ': *'"
                class="modal-personal-input"
              />

              <BaseInput
                v-model="city"
                type="text"
                :label="$t('City') + ': *'"
                class="modal-personal-input"
              />

              <BaseInput
                v-model="postCode"
                type="text"
                :label="$t('Post Code') + ': *'"
                class="modal-personal-input"
              />
            </div>
          </div>

          <div class="modal-personal-bottom-section">
            <div class="modal-personal-terms-row">
              <BaseCheckbox
                v-model="terms"
                id="accept-terms"
                @change="clearError('acceptTerms')"
              >
                <span class="">{{ $t("I accept") }} </span>

                <router-link
                  :to="privacyLink"
                  target="_blank"
                  class="desc"
                  v-if="privacyLink"
                  >{{ $t("privacy policy") }}</router-link
                >

                <span class=""> {{ $t("and") }} </span>

                <router-link
                  :to="termsLink"
                  class="desc"
                  v-if="termsLink"
                  target="_blank"
                  >{{ $t("terms and conditions") }}
                </router-link>
              </BaseCheckbox>

              <div class="modal-personal-total">
                <span class="modal-personal-total-text">TOTAL:</span>
                <span class="modal-personal-total-amount">{{ amount }} €</span>
              </div>
            </div>

            <div class="modal-personal-payment-section">
              <div class="modal-personal-payment-methods">
                <img
                  src="./../assets/visa.svg"
                  alt="Visa"
                  class="modal-personal-payment-icon"
                />
                <img
                  src="./../assets/mastercard.svg"
                  alt="Mastercard"
                  class="modal-personal-payment-icon"
                />
                <img
                  src="./../assets/3ds.png"
                  alt="3D Secure"
                  class="modal-personal-security-icon"
                />
              </div>

              <button
                @click="submit"
                :disabled="!requiredFieldsAreFilled"
                class="modal-personal-submit-button button secondary"
              >
                {{ $t("Debit/Credit Card") }}
              </button>
            </div>
          </div>

          <div v-if="errorMessage" class="modal-personal-error-message">
            {{ errorMessage }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

// import Select2 from 'vue3-select2-component';
export default {
  name: "TopUpModal",
  props: [],
  components: {
    // Select2
  },
  data: function () {
    return {
      amount: "",
      amountStep: true,
      email: "",
      name: "",
      surname: "",
      phone: "",
      country: "",
      countryId: "",
      city: "",
      address: "",
      postCode: "",
      terms: false,
      countryOptions: [],
      countryOptionsId: [],
      errorMessage: null,
    };
  },
  mounted() {
    this.getCountries();
    this.$http
      .get(process.env.VUE_APP_API + "user/profile")
      .then((res) => {
        this.email = res.data.email;
        this.name = res.data.name;
        this.surname = res.data.surname;
        this.phone = res.data.phone;
      })
      .catch((res) => {
        if (res.response.status === 401) {
          this.$parent.openSignIn();
        }
      });
  },
  computed: {
    ...mapGetters("staticPages", ["getPrivacyPage", "getTermsPage"]),

    privacyLink() {
      const page = this.getPrivacyPage;
      return page ? `/pages/${page.slug}` : null;
    },

    termsLink() {
      const page = this.getTermsPage;
      return page ? `/pages/${page.slug}` : null;
    },
    requiredFieldsAreFilled() {
      let fieldsAreFilled;
      if (
        this.name &&
        this.surname &&
        this.phone &&
        this.amount &&
        this.country &&
        this.city &&
        this.address &&
        this.postCode &&
        this.terms
      ) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    },
  },
  methods: {
    selectCountry(event) {
      this.country = event;
      for (let i = 0; i < this.countryOptions.length; i++) {
        if (this.countryOptions[i] == event) {
          this.countryId = this.countryOptionsId[i];
        }
      }
    },
    getCountries() {
      this.$http
        .get(process.env.VUE_APP_API + "countries")
        .then((res) => {
          if (res.data.status === "OK" && Array.isArray(res.data.payload)) {
            this.countryOptions = res.data.payload;
          } else {
            console.error("Invalid API response format");
            this.errorMessage = "Error loading countries";
          }
        })
        .catch((error) => {
          console.error("API error:", error);
          this.errorMessage = "Error loading countries";
          if (error.response?.status === 401) {
            this.$parent.openSignInModal();
          }
        });
    },
    submit() {
      if (!this.country || typeof this.country !== "number") {
        this.errorMessage = "Please select a valid country";
        return;
      }

      let data = {
        name: this.name,
        surname: this.surname,
        phone: this.phone,
        amount: this.amount,
        country: this.country,
        city: this.city,
        address: this.address,
        postCode: this.postCode,
      };
      this.$http
        .post(process.env.VUE_APP_API + "deposits/checkout", data)
        .then((res) => {
          window.location = res.data.redirect_url;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignIn();
          } else if (res.response.status === 400) {
            if (res.response.data.message) {
              this.errorMessage = res.response.data.message;
            }
          }
        });
    },
    nextStep() {
      if (this.amount) {
        this.amountStep = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-amount-input-group {
  min-width: 126px;
  max-width: min-content;
  width: 100%;
  text-align: center;
  margin: 0 auto;
}

::v-deep(.amount-input) input {
  padding: 6px 40px;
  text-align: center;
}
.currency {
  position: absolute;
  top: 50%;
  right: 17px;
  font-size: 18px;
  transform: translateY(-50%);
}

.modal-amount-title {
  margin-bottom: 51px;
  text-transform: uppercase;
}
.offer-modal .wrapper {
  padding: 46px;
}

.offer-modal .modal-amount-button {
  margin: 32px 0 0;
}
.offer-modal .modal-personal-info-wrapper {
  max-height: calc(100vh - 48px);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 1020px;
  background: #433144;
  border-radius: 12px;
  padding: 46px 105px;
  z-index: 1000;
}

.modal-close-icon {
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
}

.modal-personal-title {
  font-family: "Outfit", sans-serif;
  font-size: 32px;
  font-weight: 800;
  line-height: 40.32px;
  text-align: center;
  color: #fff;
  margin-bottom: 51px;
}

.modal-personal-form-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin-bottom: 24px;
}

.modal-personal-form-column {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.modal-personal-input {
  ::v-deep(.base-input) {
    width: 393px;
    height: 64px;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: transparent;
    color: #fff;
    padding: 0 20px;
    font-family: "Outfit", sans-serif;
    font-size: 16px;

    &::placeholder {
      color: rgba(255, 255, 255, 0.5);
    }
  }

  ::v-deep(.base-input-label) {
    color: #fff;
    font-family: "Outfit", sans-serif;
    font-size: 14px;
    margin-bottom: 8px;
  }
}

.modal-personal-bottom-section {
  margin-top: 24px;
}

.modal-personal-terms-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.modal-personal-checkbox-label {
  display: flex;
  align-items: center;
  gap: 12px;
  color: #fff;
}

.modal-personal-checkbox-input {
  display: none;

  &:checked + .modal-personal-checkbox-custom:after {
    display: block;
  }
}

.modal-personal-checkbox-custom {
  width: 24px;
  height: 24px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    display: none;
    left: 8px;
    top: 4px;
    width: 6px;
    height: 12px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}

.modal-personal-terms-text {
  font-family: "Outfit", sans-serif;
  font-size: 14px;
}

.modal-personal-terms-link {
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}

.modal-personal-total {
  max-width: 393px;
  width: 100%;
  text-align: center;
  font-family: "Outfit", sans-serif;
  font-size: 32px;
  font-weight: 800;
  color: #fff;

  .modal-personal-total-amount {
    margin-left: 12px;
  }
}

.modal-personal-payment-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-personal-payment-methods {
  display: flex;
  align-items: center;
  gap: 17px;
}

.modal-personal-payment-icon,
.modal-personal-security-icon {
  height: 24px;
  width: auto;
}

.offer-modal .modal-personal-submit-button {
  max-width: 393px;
  margin: 0;
}

.modal-personal-error-message {
  color: #ff4444;
  margin-top: 16px;
  text-align: center;
}

// Keep existing amount step styles
.modal-amount-wrapper {
  text-align: center;
}

.modal-amount-input {
  ::v-deep(input) {
    padding: 6px 40px;
    text-align: center;
  }
}

.modal-amount-currency {
  position: absolute;
  top: 50%;
  right: 17px;
  font-size: 18px;
  transform: translateY(-50%);
}

// Responsive styles
@media (max-width: 1280px) {
  .modal-personal-info-wrapper {
    width: 90%;
    padding: 32px;
  }

  .modal-personal-form-grid {
    grid-template-columns: 1fr;
  }

  .modal-personal-input {
    ::v-deep(.base-input) {
      width: 100%;
    }
  }

  .modal-personal-terms-row {
    flex-direction: column;
    gap: 24px;
  }

  .modal-personal-payment-section {
    flex-direction: column;
    gap: 24px;
  }
}

@media (max-width: 768px) {
  .offer-modal .modal-personal-info-wrapper {
    padding: 46px 32px;
  }
  .modal-amount-input-group {
    padding: 16px;
  }
  .modal-personal-title {
    font-size: 24px;
    line-height: 32px;
  }

  .modal-personal-total {
    font-size: 24px;
  }
}
</style>
